import React from 'react'
import './index.less'
import MainHeader from '../../components/common/MainHeader'
import { Button } from 'antd'
import { Col, Container, Footer, Row } from '../../components/common'
import Laptop from '../../assets/b/our-work/laptop-white.png'
import TestimonialSlideShow from './TestimonialSlideShow'
import ProblemStatement from './ProblemStatement'
import { useParams } from 'react-router-dom'
import ourWorkData from './data'

const tempTestimonials = [
  {
    img: 'https://via.placeholder.com/150',
    name: '<NAME>',
    designation: 'CEO, Bot2Do Technologies',
    comment:
      'We are greatly impressed by the professional attitude shown by the Atri team whether it is the KavimERP implementation, after sales support that has been given to us. There has been excellent support in terms of providing the services well on time & they truly stand by their motto in terms of creating enduring value for customers.',
  },
  {
    img: 'https://via.placeholder.com/150',
    name: '<NAME>',
    designation: 'CEO, Bot2Do Technologies',
    comment:
      'We are greatly impressed by the professional attitude shown by the Atri team whether it is the KavimERP implementation, after sales support that has been given to us. There has been excellent support in terms of providing the services well on time & they truly stand by their motto in terms of creating enduring value for customers.',
  },
  {
    img: 'https://via.placeholder.com/150',
    name: '<NAME>',
    designation: 'CEO, Bot2Do Technologies',
    comment:
      'We are greatly impressed by the professional attitude shown by the Atri team whether it is the KavimERP implementation, after sales support that has been given to us. There has been excellent support in terms of providing the services well on time & they truly stand by their motto in terms of creating enduring value for customers.',
  },
]
const OurWorkTemplate = () => {
  const { id } = useParams()
  const caseData = ourWorkData[id - 1] || undefined
  if (caseData === undefined) {
    window.location.href = '/#our-work'
    return
  }

  return (
    <div className='openpage-wrapper'>
      <MainHeader />
      <div className='openpage-section our-work'>
        <section className='banner-section'>
          <Container>
            <div className='banner-heading'>
              <h1>
                {caseData.name} for Leading {caseData.domain}&nbsp;Organization
              </h1>
            </div>
            <div className='banner-img-wrapper'>
              <img
                data-aos='fade-in'
                data-aos-duration='700'
                className='banner-img'
                srcSet={`${
                  caseData.heroImg
                    ? `/static/our-work/${caseData.heroImg}`
                    : '/static/our-work/laptop-white.png'
                }`}
                alt='banner-laptop'
              />
              {/* <img
                src={`/static/our-work/${caseData.heroImg}`}
                alt={caseData.heroImg}
                className='banner-case-img'
              /> */}
            </div>
          </Container>
        </section>
        <section className='our-work-details'>
          <Container>
            <span className='info'>
              {/* This case study delves into the successful implementation of an
              advanced HRMS solution for a prominent healthcare organization in
              India. Discover how Bot2Do improved operational effectiveness by
              transforming HR procedures, streamlining workflows, and
              encouraging well-informed decision-making. */}
            </span>
            <div className='about-customer'>
              <h2>About Customer</h2>
              <ul>
                <li>
                  <span>Industry</span>
                  <span>– {caseData.domain}</span>
                </li>
                <li>
                  <span>System Users</span>
                  <span> {caseData.users || '1000+'}</span>
                </li>
                <li>
                  <span>Location/Country</span>
                  <span>– {caseData.location}</span>
                </li>
              </ul>
            </div>
            <div className='info'>
              {/* As a leading healthcare organization in India, our client faced
              intricate challenges in managing HR processes efficiently. The
              existing systems lacked seamless integration, leading to data flow
              inefficiencies and operational bottlenecks. Bot2Do stepped in to
              enhance reporting, streamline operations, and provide an
              integrated HRMS solution. */}
            </div>
          </Container>
        </section>

        <section className='problem-statement'>
          <Container>
            <ProblemStatement id={id} />
          </Container>
        </section>
        <section className='testimonials'>
          {/* <Container> */}
          <div className='testimonials-heading'>
            <div className='testimonials-heading-wrapper'>
              <span className='heading-text'> TESTIMONIALS</span>
              <span className='heading-underline'></span>
            </div>

            <h2>What Our Clients Say About Us?</h2>
          </div>
          <div className='testimonial-slideshow-wrapper'>
            <TestimonialSlideShow />
          </div>
          {/* </Container> */}
        </section>
        <section className='faq-section'>
          <Container>
            <Row>
              <Col>
                <div className='video-row'>
                  <label data-aos='fade-up' data-aos-duration='900'>
                    Contact Us
                  </label>
                  <h1 data-aos='fade-up' data-aos-duration='1000'>
                    SCHEDULE A CONSULTATION <br /> AND DISCOVER HOW WE CAN{' '}
                    <br /> ELEVATE YOUR BUSINESS!
                  </h1>
                  <a
                    href='https://cal.com/bot2do/30min'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button
                      className='get-btn'
                      data-aos='fade-up'
                      data-aos-duration='1000'
                    >
                      Contact Us
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default OurWorkTemplate
