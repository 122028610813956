/* BlogArticle.jsx */
import React from "react";
import { blogs } from "../../blogData";
import "./BlogArticle.less";

const BlogArticle = ({ subBlogId }) => {
  console.log("first subBlogId", subBlogId);

  const subBlogIdNumber = parseInt(subBlogId);

  const blogPost = blogs.find((blog) => blog.id === subBlogIdNumber);

  return (
    <div className="blog-article-container">
      <div className="blog-content">
        <div className="content-section">
          {blogPost?.content &&
            Object.entries(blogPost?.content).map(
              ([title, { innercontent }]) => (
                <div key={title} id={title} className="section">
                  <h3 className="section-title">{title}</h3>
                  <div
                    className="section-content"
                    dangerouslySetInnerHTML={{ __html: innercontent }}
                  ></div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
