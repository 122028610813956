import React from "react";
import { useParams } from "react-router-dom";
import "./BlogMainPage.less";
import MainHeader from "../../components/common/MainHeader";
import { Container, Footer } from "../../components/common";
import { useCategory } from "../../context/CategoryContext";
import GridBlogsCards from "./GridBlogsCards";

const BlogPage = () => {
  const { id } = useParams();
  const { handleNavLinkClick, selectedCategory } = useCategory();

  const navLinks = [
    "All",
    "Health Care",
    "Supply Chain",
    "Retail",
    "Telecommunications",
  ];

  console.log("id in blog", id);
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section templates-page services">
        <section className="banner-section">
          <Container>
            <div className="main-page-container">
              <h2 className="main-page-heading">Discover Our Blogs</h2>
              <ul className="nav-links-list">
                {navLinks.map((link, index) => (
                  <li key={index} className="nav-link-item">
                    <button
                      onClick={() => handleNavLinkClick(link)}
                      className={`nav-link-button ${
                        selectedCategory === link ? "active" : ""
                      }`}
                    >
                      {link}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {/* GridBlogsCards component */}
            <GridBlogsCards />

          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default BlogPage;
