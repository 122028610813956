import React from 'react'
import './index.less'

const TeamMemberCard = ({ style, members, backgroundBg }) => {
  return (
    <div className='team-member-wrapper' style={style}>
      <div className='team-member' style={{ background: backgroundBg }}>
        <div className='member-wrapper'>
          {members?.map((member, index) => {
            return (
              <div className='member'>
                <div className='img-wrapper'>
                  <img
                    src={`/static/bot2do/team/${member.img}`}
                    alt={member.name}
                  />
                </div>
                <div className='title'>
                  <div className='name'>{member.name}</div>
                  <div className='designation'>- {member.designation}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TeamMemberCard
