import { useEffect, useState } from 'react'
import ExpandIcon from './ExpandIcon'
import MegaMenu from './MegaMenu'
import { service } from './services.data'
// service={service} key={index}
import './index.less'

export const ServiceLi = ({ _service }) => {
  const { title, list, isSubMenuList, href } = service
  const [isHovered, setIsHovered] = useState(false)
  const [enterInterval, setEnterInterval] = useState(null)
  const [mobileView, setMobileView] = useState(false)
  const expandStyle = {
    transform: 'scaleY(-1)',
    color: '#0A3794',
  }
  const liStyle = {
    borderColor: '#0A3794',
    zIndex: 999,
  }
  useEffect(() => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      console.log('mobile')
      if (mobileView) {
        return
      }
      setMobileView(true)
    }
    window.addEventListener('resize', () => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        console.log('mobile')
        if (mobileView) {
          return
        }
        setMobileView(true)
      }
    })
    return () => {
      window.removeEventListener('resize', () => {
        if (window.matchMedia('(max-width: 767px)').matches) {
          console.log('mobile')
          if (mobileView) {
            return
          }
          setMobileView(true)
        }
      })
    }
  }, [])

  return (
    <li
      className='has-submenu'
      onMouseEnter={() => {
        // if (mobileView) {
        //   return
        // }
        setIsHovered(true)
        clearTimeout(enterInterval)
      }}
      onClick={() => {
        // if (mobileView) {
        //   if (isHovered) {
        //     setIsHovered(false)
        //   } else {
        //     // setIsHovered(true)
        //   }
        // }
      }}
      onMouseDown={() => {
        // if (mobileView) {
        //   return
        // }
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        // if (mobileView) {
        //   return
        // }
        const interval = setTimeout(() => setIsHovered(false), 200)
        // setIsHovered(false)
        // console.log('isHovered', false)
        // setIsHovered(false)
        setEnterInterval(interval)
      }}
      style={isHovered ? liStyle : null}
    >
      <div className='has-menu-text'>
        <a
          onClick={() => null}
          className={`anchor-tag  ${isHovered ? 'hover' : ''}`}
        >
          {title}
        </a>
        <ExpandIcon style={isHovered ? expandStyle : null} />
      </div>
      {/* <ExpandIcon /> */}
      <MegaMenu
        list={list}
        hover={isHovered}
        onMouseEnter={() => {
          if (mobileView) {
            return
          }
          if (!isHovered) {
            setIsHovered(false)
          }
        }}
        onMouseLeave={() => {
          if (mobileView) {
            return
          }
          setIsHovered(false)
        }}
        setHover={setIsHovered}
        isSubMenuList={isSubMenuList}
        mobileView={mobileView}
      />
    </li>
  )
}
