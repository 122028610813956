import './ProblemStatement.less'
import React, { useEffect, useState } from 'react'

import ourWork from '../data'
import ProblemStatementCard from './ProblemStatementCard'
import EmailModal from './EmailModal'

const ProblemStatement = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  // const [sticky, setSticky] = useState(false)
  // const [position, setPosition] = useState(null)
  useEffect(() => {
    // const titleWrapper = document.querySelector('.title-wrapper')
    // const descriptionWrapper = document.querySelector('.description-wrapper')
    // const handleScroll = () => {
    //   const descriptionBottom =
    //     descriptionWrapper.getBoundingClientRect().bottom
    //   const titleHeight = titleWrapper.offsetHeight
    //   console.log(descriptionBottom, titleHeight)
    //   if (descriptionBottom < 180) {
    //     setSticky(false)
    //     setPosition(-1)
    //   } else if (titleHeight + 410 - descriptionBottom > 330) {
    //     setSticky(true)
    //     setPosition(80)
    //   } else {
    //     setSticky(false)
    //     setPosition(null)
    //   }
    // }
    // window.addEventListener('scroll', handleScroll)
    // return () => {
    //   window.removeEventListener('scroll', handleScroll)
    // }
  }, [])

  // console.log(position)
  // const { data } = props;
  const { id } = props
  const { data } = ourWork[id - 1]
  console.log('case data', data)
  return (
    <div className='problem-statement-wrapper'>
      {data.map((item, index) => {
        const { title, desc, isSticky, isImg } = item
        console.log(index, localStorage.getItem(id))

        if (
          title === 'Solution Provided' &&
          localStorage.getItem(id) != 'true'
        ) {
          return (
            <EmailModal
              id={id}
              key={id}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
            />
          )
        } else if (
          title === 'Technologies Used' &&
          localStorage.getItem(id) != 'true'
        ) {
          return <></>
        }
        return (
          <ProblemStatementCard
            key={index}
            index={index}
            title={title}
            desc={desc}
            isSticky={isSticky}
            isImg={isImg}
          />
        )
      })}
    </div>
  )
}

export default ProblemStatement
