/*

{
  id: ,
  name:  Name of case study,
  domain: Domain,
  companyName: Company Name,
  companyImg: comapny image name,
  time: time to read,
  heroImg:  banner image,
  location: country,
  users: system users,
  data:[
    {
      title: 'Challenge Facebed by customer' | 
      desc: {
        text:
        solutionPoints?: [[{heading, points(2)} ], [{heading, points(2)}]]
      }

    }
  
  ]
}
*/

const ourWork = [
  // {
  //   id: 1,
  //   name: 'Data Warehouse', // category
  //   domain: 'Pharmaceuticals & Healthcare',
  //   companyName: 'Johnson & Johnson',
  //   companyImg: 'johnson-johnson-logo-png-transparent.png',
  //   time: '1',
  //   heroImg: '',
  //   location: 'USA',

  //   users: '1000+',
  //   data: [
  //     {
  //       title: 'Challenge Faced by the Customer',
  //       desc: {
  //         text: 'There were multiple wholesalers and retailers who are tracking their sales and orders at an individual levels. The support system which they possessed were being handled manually which was being connected to one single database. Due to this there were many issues such as mismatching of data, missing data and time consuming process. Also, the tracking of order was not up-to-date in the system, due to which the orders get delayed.',
  //       },
  //     },
  //     {
  //       title: 'Solution Provided',
  //       isSticky: true,
  //       desc: {
  //         text: 'We designed a system in which the single end point of gathering the data from their systems and entering in one single data warehouse. In which we designed API endpoints. We created an Azure Queue which listens to a function. 2 APIs. First API sends all data. It sees which which client has called the API which filters different data containing the same meaning of the data. It pushes the data to the queue. The function attached to the queue validates the data according to the existing data in the database.',
  //         solutionPoints: [
  //           [
  //             {
  //               heading: 'Manual Work reduced',
  //               points: [
  //                 'Implementation of automation tools for repetitive tasks.',
  //                 'Integration of workflow systems to streamline processes.',
  //                 'Adoption of AI-powered solutions for routine operations.',
  //               ],
  //             },
  //             {
  //               heading: 'Clean Data',
  //               points: [
  //                 'Implementation of data cleansing algorithms and tools.',
  //                 'Regular data quality checks and validation procedures.',
  //                 'Integration of data governance policies for maintaining data integrity.',
  //               ],
  //             },
  //             {
  //               heading: 'Order tracking is more accurate',
  //               points: [
  //                 'Real-time tracking systems for accurate order status updates.',
  //                 'Integration of GPS and RFID technologies for precise tracking.',
  //                 'Implementation of advanced analytics to predict and prevent delays.',
  //               ],
  //             },
  //             {
  //               heading: 'No extra resources required for maintenance',
  //               points: [
  //                 'Adoption of self-healing systems to minimize downtime.',
  //                 'Utilization of predictive maintenance models to address issues proactively.',
  //                 'Automated monitoring and alert systems for efficient resource management.',
  //               ],
  //             },
  //             {
  //               heading: 'Serverless Data Integration',
  //               points: [
  //                 'Implementation of serverless architecture for scalable data integration.',
  //                 'Adoption of cloud-based data integration platforms.',
  //                 'Utilization of event-driven architecture for real-time data processing.',
  //               ],
  //             },
  //             {
  //               heading: 'Efficient SAP Integration',
  //               points: [
  //                 'Seamless integration of SAP modules with other business systems.',
  //                 'Utilization of SAP connectors for smoother data flow.',
  //                 'Implementation of best practices for SAP integration.',
  //               ],
  //             },
  //             {
  //               heading: 'Enhancing Overall Operational Efficiency',
  //               points: [
  //                 'Continuous process optimization through data-driven insights.',
  //                 'Implementation of lean and agile methodologies.',
  //                 'Adoption of collaborative tools for improved communication and coordination.',
  //               ],
  //             },
  //           ],
  //         ],
  //       },
  //     },
  //     {
  //       title: 'Technologies Used',
  //       isImg: true,
  //       desc: {
  //         data: [
  //           {
  //             tag: 'Python',
  //             img: 'python.png',
  //           },
  //           {
  //             tag: 'Python',
  //             img: '',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
  {
    id: 2,
    name: 'AI Driven Procurement Management System',
    domain: 'Supply Chain Management',
    companyName: 'Kavida',
    companyImg: 'kavida.jpeg',
    time: '1',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 3,
    name: 'Realtime Order Management System',
    domain: 'Sportswear and Footwear Retailer',
    companyName: 'Footlocker',
    companyImg: 'foot-locker-logo.svg',
    time: '2',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 4,
    name: 'Telecom Provisioning System',
    domain: 'Communications Services',
    companyName: 'British Telecom',
    companyImg: 'British_Telecom-logo.png',
    time: '1',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 5,
    name: 'Supply Chain Control Tower',
    domain: 'Athletic Apparel Retailer',
    companyName: 'Lululemon',
    companyImg: 'Lululemon_Athletica_logo.svg.png',
    time: '4',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 6,
    name: 'Order Commits & Sourcing',
    domain: 'Cosmetics',
    companyName: 'Sephora',
    companyImg: 'Sephora-Logo.png',
    time: '1',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 7,
    name: 'Realtime Inventory Management System',
    domain: 'Supply Chain Management',
    companyName: 'GXO',
    companyImg: 'GXO_logo.png',
    time: '1',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 8,
    name: 'Supply Chain Control Tower Analytics Platform',
    domain: 'Retail Apparel and Fashion',
    companyName: 'Stitchfix',
    companyImg: 'stitch-fix19565.logowik.com.webp',
    time: '1',
    heroImg: '',
    location: '',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: { text: '' },
      },
      {
        title: 'Solution Provided',
        desc: { text: '' },
        isSticky: true,
        solutionPoints: [
          [
            {
              heading: '',
              points: [''],
            },
          ],
        ],
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: '',
              img: '',
            },
            {
              tag: '',
              img: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 9,
    name: 'AI Driven Automated Cloud Deployments',
    domain: 'IT Solutions',
    companyName: 'Solarsys',
    companyImg: 'solarsys.jpeg',
    heroImg: '9_hero_solarsys.png',
    time: '3',
    location: 'Australia',
    users: '1000+',
    data: [
      {
        title: 'Challenge Faced by the Customer',
        desc: {
          text: 'Their current Cloud Deployments are confluence guided self-service implementation. They were facing frequent challenges involving machine kind, quota, role access, and internal and external DNS setup problems, in some cases needed to restart from scratch. The need for L1 support team, along with the time-consuming context setup and delayed incident resolution, were causing significant development time delays.',
        },
      },
      {
        title: 'Solution Provided',
        isSticky: true,
        desc: {
          text: 'These difficulties had been avoided by using an AI-based chatbot to address issues like insufficient IPs, quotas, and DNS instead of waiting for experts to get involved. The repetition of setting up the background would be reduced by using a generative AI-based chatbot. Creating an AI-based chatbot for cloud infrastructure provisioning with a context-based knowledge base using generative AI could significantly enhance the efficiency and user experience of managing cloud resources. Along with automation using chatbots, approval workflows could also be incorporated by sending an email notification to approvers with all the details, including the cost and other security aspects.',
          solutionPoints: [
            [
              {
                heading: 'Cost Saving',
                points: [
                  'Implementation of cost-effective automation solutions.',
                  'Optimization of resource allocation to reduce wastage.',
                  'Negotiation of better deals with suppliers and vendors.',
                ],
              },
              {
                heading: 'Efficiency and Productivity',
                points: [
                  'Utilization of advanced algorithms for task prioritization.',
                  'Integration of machine learning for personalized responses.',
                  'Implementation of chatbot analytics for continuous improvement.',
                ],
              },
              {
                heading: 'Scalability',
                points: [
                  'Utilization of cloud-based infrastructure for flexible scaling.',
                  'Implementation of load balancing algorithms for efficient resource utilization.',
                  'Integration of auto-scaling features to adjust capacity based on demand.',
                ],
              },
              {
                heading: 'Faster Provisioning',
                points: [
                  'Deployment of self-service portals for quick resource provisioning.',
                  'Integration of automated approval workflows for faster processing.',
                  'Utilization of infrastructure-as-code for rapid environment setup.',
                ],
              },
              {
                heading: '24/7 Availability',
                points: [
                  'Implementation of redundant systems for uninterrupted service.',
                  'Utilization of chatbot analytics for identifying peak usage times.',
                  'Integration of automated monitoring for immediate issue resolution.',
                ],
              },
            ],
          ],
        },
      },
      {
        title: 'Technologies Used',
        isImg: true,
        desc: {
          data: [
            {
              tag: 'Terraform',
              img: '',
            },
            {
              tag: 'NoSQL DB',
              img: '',
            },
            {
              tag: 'Kubernetes',
              img: '',
            },
            {
              tag: 'Docker',
              img: '',
            },
          ],
        },
      },
    ],
  },

  // {
  //   id: 10,
  //   name: 'AWS Decoupling Architecture',
  //   domain: 'Pharmaceuticals & Healthcare',
  //   companyName: 'Johnson & Johnson',
  //   companyImg: 'johnson-johnson-logo-png-transparent.png',
  //   heroImg: '',
  //   time: '2',
  //   location: 'USA',
  //   users: '1000+',
  //   data: [
  //     {
  //       title: 'Challenge Faced by the Customer',
  //       desc: {
  //         text: 'The legacy MSSQL server in their system was slowing things down and had inefficient data handling and limited analytical capabilities due to which their B2B clients had to wait a long time to get the data they needed, resorting to old-fashioned methods like making CSV files and using Excel for analysis. They needed to upgrade their system to solve this issue.',
  //       },
  //     },
  //     {
  //       title: 'Solution Provided',
  //       isSticky: true,
  //       desc: {
  //         text: 'Solution 1 proposes a short-term approach to address limitations in the existing system, primarily focused on overcoming the constraints of the AWS API Gateway. By implementing a custom Message Router deployed on EKS Service and exposed via Ingress HTTP Controller, it bypasses the 10MB sizing and 29 seconds request timeout limitations. Security is enhanced with JWT token authentication. However, drawbacks include continued Lambda 15 mins request timeout, complex migration activities, dependency on multiple databases, and high costs.',
  //         solutionPoints: [
  //           [
  //             {
  //               heading: 'Short-Term Approach Highlights',
  //               points: [
  //                 'Overcomes AWS API Gateway limitations on request timeout and payload sizing.',
  //                 'Enhanced security with JWT token authentication.',
  //                 'Avoids data compression and decompression on AWS side.',
  //               ],
  //             },
  //             {
  //               heading: 'Challenges to Consider',
  //               points: [
  //                 'Continued Lambda 15 mins request timeout.',
  //                 'Complex migration activities.',
  //                 'Dependency on multiple databases and high associated costs.',
  //               ],
  //             },
  //             {
  //               heading: 'Operational Considerations',
  //               points: [
  //                 'High developer effort and time consumption due to multiple components.',
  //                 'Management complexity with multiple services.',
  //                 'Potential scalability challenges with increased costs.',
  //               ],
  //             },
  //             {
  //               heading: 'Cost Analysis',
  //               points: [
  //                 'Higher costs due to larger storage, AWS managed services, Lambda requests, and bandwidth.',
  //                 'Potential cost optimizations required for long-term sustainability.',
  //                 'Resource-intensive setup and ongoing management.',
  //               ],
  //             },
  //           ],
  //           [
  //             {
  //               heading: 'Long-Term Approach Highlights',
  //               points: [
  //                 'Overcomes AWS API Gateway limitations on request timeout and payload sizing.',
  //                 'Deployment on a single EKS platform streamlines manageability.',
  //                 'Lower costs compared to Solution 1, with payment only for EKS nodes (EC2 machines).',
  //               ],
  //             },
  //             {
  //               heading: 'Implementation Challenges',
  //               points: [
  //                 'Requires one-time implementation effort for EKS cluster setup and deployments.',
  //                 'Resource training needed to understand EKS workflow.',
  //                 'Potential learning curve for team members transitioning to EKS.',
  //               ],
  //             },
  //             {
  //               heading: 'Operational Advantages',
  //               points: [
  //                 'Easier manageability for developers and support teams with a single platform.',
  //                 'Scalability potential with simplified architecture.',
  //                 'Reduced dependency on AWS managed services.',
  //               ],
  //             },
  //             {
  //               heading: 'Cost Efficiency',
  //               points: [
  //                 'Lower costs compared to Solution 1 due to simplified architecture.',
  //                 'Potential cost savings in the long run with efficient resource utilization.',
  //                 'Streamlined cost management with fewer services to maintain.',
  //               ],
  //             },
  //           ],
  //         ],
  //       },
  //     },
  //     {
  //       title: 'Technologies Used',
  //       isImg: true,
  //       desc: {
  //         data: [
  //           {
  //             tag: 'Terraform',
  //             img: '',
  //           },
  //           {
  //             tag: 'NoSQL DB',
  //             img: '',
  //           },
  //           {
  //             tag: 'Kubernetes',
  //             img: '',
  //           },
  //           {
  //             tag: 'Docker',
  //             img: '',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
]

export default ourWork
