// BlogInfo.jsx
import React from "react";
import { blogs } from "../../blogData";
import "./BlogInfo.less";

const BlogInfo = ({ subBlogId }) => {
  console.log("subBlogId in BlogInfo", subBlogId);

  const subBlogIdNumber = parseInt(subBlogId); // Convert subBlogId to a number
  console.log("subBlogIdNumber", subBlogIdNumber);

  const blogPost = blogs.find((blog) => blog.id === subBlogIdNumber);
  console.log("first blogPost", blogPost);
  console.log("blogPost?.heading", blogPost?.heading);

  return (
    <div className="blog-info-container">
      <h1 className="blog-heading">{blogPost?.heading}</h1>
      <p className="blog-time">{blogPost?.time}</p>
    </div>
  );
};

export default BlogInfo;
