// CategoryContext.js
import React, { createContext, useState, useContext } from "react";

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleNavLinkClick = (category) => {
    console.log("category in context", category)
    setSelectedCategory(category);
  };

  return (
    <CategoryContext.Provider value={{ selectedCategory, handleNavLinkClick }}>
      {children}
    </CategoryContext.Provider>
  );
};
