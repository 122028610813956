import { Container, Footer } from '../../components/common'
import MainHeader from '../../components/common/MainHeader'
import React from 'react'
import './index.less'
import './colorAnimation.less'
import LeadershipTeam from './LeadershipTeam'

const AboutUS = () => {
  return (
    <div className='openpage-wrapper'>
      <MainHeader />
      <div className='openpage-section templates-page about-us'>
        <section className='banner-section'>
          <Container>
            <div className='banner-content'>
              <h1>
                Team that Engineers your&nbsp;
                <span className='heading-light-blue'>Next Digital Product</span>
              </h1>
              <p>
                In an era where every organization is transitioning into a
                data-centric and cloud-powered organization. Bot2Do Technologies
                stands as your ally in navigating this transformative landscape.
                Yes, your company included! Our mission is clear: to equip
                businesses with the technological edge needed to thrive in
                tomorrow's competitive arena.
                <br />
                <br />
                Bot2Do is more than just an IT services provider; we are your
                digital age strategic partner. Since our inception in 2014 with
                a vision, we've been at the forefront of assisting companies
                ranging from young startups to Fortune 500 companies in their
                digital transformation journey.
                <br />
                <br />
                Bot2Do Technologies is a cloud and data services force dedicated
                to unraveling the intricacies of software engineering
                difficulties. Our aim is clear: we're committed to altering your
                technological landscape, and transforming possible bottlenecks
                into dynamic growth catalysts.
              </p>
            </div>
          </Container>
        </section>
        {/* <section className='leadership-team-section'>
          <Container>
            <LeadershipTeam />
          </Container>
        </section> */}
        <Footer />
      </div>
    </div>
  )
}

export default AboutUS
