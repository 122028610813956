import React, { useState } from 'react'
import blurImg from '../../../../assets/b/our-work/blur text.png'
import axios from 'axios'

import MailchimpSubscribe from 'react-mailchimp-subscribe'
import './index.less'

const EmailModal = ({ id, setFormSubmitted, formSubmitted }) => {
  const [email, setEmail] = useState('')

  // const handleSubmit = async (e) => {
  //   setFormSubmitted(true)
  //   e.preventDefault()
  //   setTimeout(() => {
  //     // localStorage.setItem(id, true)
  //     setFormSubmitted(false)
  //   }, 1000)

  //   //get form fileds data from e
  //   console.log(email)
  //   try {
  //     const response = await mailchimp.lists.addListMember('88448f627e', {
  //       email_address: email,
  //       status: 'subscribed',
  //     })
  //     console.log(response)
  //     // const { data } = await axios.post(
  //     //   'https://us18.api.mailchimp.com/3.0/lists/88448f627e',
  //     //   {
  //     //     members: [{ email_address: email, status: 'subscribed' }],
  //     //   },
  //     //   {
  //     //     headers: {
  //     //       Authorization: 'auth 8c1d305525a6e1d5eef5d998bd715ffb-us18',
  //     //       'Access-Control-Allow-Origin': '*',

  //     //     },

  //     //   }

  //     // )
  //     // console.log(data)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   //post request to mailchimp url
  //   // const response = await fetch(
  //   //   'https://bot2do.us18.list-manage.com/subscribe/post?u=4a801c14607096e8902c8108c&amp;id=88448f627e&amp;f_id=00e8c2e1f0',
  //   //   {
  //   //     method: 'POST',
  //   //     mode: 'no-cors',
  //   //     headers: {
  //   //       'Access-Control-Allow-Headers': '*',
  //   //     },

  //   //     body: {
  //   //       EMAIL: email,
  //   //     },
  //   //   }
  //   // )

  //   // console.log(response)

  //   // Set the flag in local storage indicating the form has been submitted
  // }

  const url =
    'https://bot2do.us18.list-manage.com/subscribe/post?u=4a801c14607096e8902c8108c&amp;id=88448f627e&amp;f_id=00e8c2e1f0'

  return (
    <div className='email-box-wrapper'>
      <div className='blur-img'>
        <img src={blurImg} alt='subscribe-to-see' />
      </div>
      <div class='box'>
        <img
          src='https://lh3.google.com/u/0/d/1-WTX2Kw4ZlgLnwT9gYyFOxVXowT9eS-t=w1375-h881-iv1'
          alt=''
        />
        <h2>Our Exclusive Solution</h2>
        <p>
          Enter your email below to gain access to our full range of expert
          solution
        </p>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            console.log(message)
            if (status === 'success') {
              localStorage.setItem(id, true)
              setFormSubmitted(true)
            }

            if (
              status === 'error' &&
              message ===
                'This email cannot be added to this list. Please enter a different email address.'
            ) {
              localStorage.setItem(id, true)
              setFormSubmitted(true)
            }

            return (
              <div class='form-control'>
                <input
                  type='email'
                  name='EMAIL'
                  required={true}
                  class='input'
                  placeholder='Enter e-mail address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  class='btn'
                  onClick={() => {
                    subscribe({ EMAIL: email })
                  }}
                >
                  {status === 'sending'
                    ? 'Revealing Solution...'
                    : status === 'success'
                    ? 'Solution almost ready...'
                    : 'Reveal Solution!'}
                </button>
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default EmailModal
