import React, { memo, useEffect, useState, useRef } from 'react'
import MainHeader from '../../components/common/MainHeader'
import { Col, Container, Footer, Row, SvgIcon } from '../../components/common'
import { Button, Card, List } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import './index.less'
import service1 from '../../assets/b/services-icon/1.svg'
import service2 from '../../assets/b/services-icon/2.svg'
import service3 from '../../assets/b/services-icon/3.svg'
import service4 from '../../assets/b/services-icon/4.svg'
import service5 from '../../assets/b/services-icon/5.svg'
import service6 from '../../assets/b/services-icon/6.svg'
import service7 from '../../assets/b/services-icon/7.svg'
import service8 from '../../assets/b/services-icon/8.svg'
import service9 from '../../assets/b/services-icon/9.svg'
import service10 from '../../assets/b/services-icon/10.svg'
import service11 from '../../assets/b/services-icon/11.svg'
import service12 from '../../assets/b/services-icon/12.svg'
import Image1 from '../../assets/b/image1.svg'
import Image2 from '../../assets/b/image2.svg'
import Image3 from '../../assets/b/image3.svg'
// import SolutionImg1 from "../../assets/b/cloud1.png";
import SolutionImg2 from '../../assets/b/cloud2.png'
import SolutionImg3 from '../../assets/b/cloud3.png'
import SolutionImg4 from '../../assets/b/cloud4.png'
import SolutionImg6 from '../../assets/b/solution-img6.jpg'
import videoOne from '../../assets/b/landing-video.mp4'
import bannerImg from '../../assets/b/bot2dohero.svg'
import { TEMPLATES } from '../../assets/template'
import ourWorkData from '../OurWorkTemplate/data'
import GCP from '../../assets/b/partners/1.png'
import AWS from '../../assets/b/partners/2.png'
import Azure from '../../assets/b/partners/3.png'
import WhatsSection from './WhatsSection'

const LandingPage = () => {
  const navigate = useNavigate()
  const [templates, settemplates] = useState([])

  useEffect(() => {
    getAllTemplates()
  }, [])

  // useEffect(() => {
  if (window.location.hash === '#our-work') {
    setTimeout(
      () =>
        document
          .getElementById('our-work')
          .scrollIntoView({ behavior: 'smooth' }),
      300
    )
  }
  // }, [window.location.hash])

  const ourWorkRef = useRef(null)

  const getAllTemplates = async () => {
    settemplates(ourWorkData)
  }

  return (
    <div className='openpage-wrapper'>
      <MainHeader />
      <div className='openpage-section landing-page'>
        <section className='banner-section'>
          <Container>
            <div className='banner-row'>
              <div className='banner-left'>
                <h1
                  data-aos='fade-up'
                  data-aos-duration='800'
                  className='heading-light-blue'
                >
                  Where Cloud <br /> Meets Clarity
                </h1>
                <a
                  href='https://cal.com/bot2do/30min'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button data-aos='fade-up' data-aos-duration='1100'>
                    <span></span> Contact Us
                    <div className='btn-icon'>
                      <SvgIcon name='arrow-right' viewbox='0 0 20.557 11.759' />
                    </div>
                  </Button>
                </a>
              </div>
              <div className='banner-right'>
                <div className='banner-right-inner'>
                  <img
                    data-aos='fade-in'
                    data-aos-duration='700'
                    className='banner-img'
                    src={bannerImg}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className='solution-section'>
          <Container>
            <Row>
              <Col>
                <div className='common-heading'>
                  <p data-aos='fade-up' data-aos-duration='800'>
                    Explore Our Expertise
                  </p>
                  <h2
                    className='small'
                    data-aos='fade-up'
                    data-aos-duration='1000'
                  >
                    Unleashing Innovation Through <br />
                    Cloud and Data Engineering Excellence
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg='8' className='left-colum'>
                <Link to='/'>
                  {/* <img
                    data-aos="fade-up"
                    data-aos-duration="700"
                    src={SolutionImg1}
                    alt="Solution"
                  /> */}
                  <img
                    data-aos='fade-up'
                    data-aos-duration='800'
                    src={SolutionImg2}
                    alt='Solution'
                  />
                  <img
                    data-aos='fade-up'
                    data-aos-duration='900'
                    src={SolutionImg3}
                    alt='Solution'
                  />
                  <img
                    data-aos='fade-up'
                    data-aos-duration='1000'
                    src={SolutionImg4}
                    alt='Solution'
                  />
                </Link>
              </Col>
              <Col lg='4' className='right-colum'>
                <div
                  className='right-colum-inner'
                  data-aos='zoom-in'
                  data-aos-duration='900'
                >
                  <div className='right-colum-content'>
                    <h2>
                      Explore Our Cloud and Data Engineering Solutions Today!
                    </h2>
                    <a
                      href='https://cal.com/bot2do/30min'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Button>Contact Us</Button>
                    </a>
                  </div>
                  <video
                    poster={SolutionImg6}
                    playsInline
                    autoPlay
                    muted
                    loop
                    className='back-video'
                  >
                    <source src={videoOne} />
                  </video>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <div
              className='customSliderHeader'
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
                marginTop: '100px',
              }}
            >
              <div className='slider-left'>
                <center>
                  <h1 className='customHeader'>
                    Partnerships with Top Tier Cloud Companies
                  </h1>
                </center>
                <center>
                  <p style={{ fontSize: '25px', lineHeight: '1.4' }}>
                    We have proudly collaborated with Google Cloud Platform,
                    Amazon Web Services, and Microsoft Azure leveraging their
                    strengths to deliver advanced and customized solutions for
                    our clients.
                  </p>
                </center>
              </div>
            </div>
            <div className='customPartners'>
              <img src={GCP} alt='GCP' />
              <img src={AWS} alt='AWS' />
              <img src={Azure} alt='Azure' />
            </div>
          </Container>
        </section>

        <section
          className='templates-section'
          data-aos='fade-in'
          data-aos-duration='1000'
          id='our-work'
          ref={ourWorkRef}
        >
          <Container>
            <div className='list-header'>
              {/* <h3>Our Work</h3> */}
              <h3>Our Clients</h3>
            </div>
            <div className='listing'>
              <List
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 4,
                  xl: 4,
                  xxl: 5,
                }}
                dataSource={templates.slice(0, 10)}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      className={
                        item.companyName === 'Create from scratch'
                          ? 'add-card'
                          : ' '
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/our-work/${item.id}`)
                      }}
                    >
                      {item.companyName === 'Create from scratch' ? (
                        <div className='add-image'>
                          <SvgIcon name='plus' viewbox='0 0 16.638 17.53' />
                        </div>
                      ) : (
                        <div className='image-upper'>
                          <img
                            src={`/static/bot2do/${item.companyImg}`}
                            alt={item.companyName}
                          />
                        </div>
                      )}

                      <div className='bottom-dtl' style={{ height: '65px' }}>
                        <div className='bottom-meta'>
                          <label>{item.name}</label>
                          <h2>
                            {item.companyName?.length > 20
                              ? item.companyName.slice(0, 20) + '...'
                              : item.companyName}
                          </h2>
                        </div>
                        {/* <div className='bottom-action'>
                          <div className='action-left'>
                            <div className='time-box'>
                              <h3>{item.time}</h3>
                              <p>Min</p>
                            </div>
                            <h4>Read</h4>
                          </div>
                          <Link
                            onClick={() => {
                              navigate(`/our-work/${item.url}`)
                            }}
                          >
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Link>
                        </div> */}
                      </div>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
          </Container>
        </section>
        <WhatsSection />
        <section className='enjoylearning-section'>
          <Container>
            <div className='enjoylearning-inner'>
              <Row>
                <Col>
                  <div className='common-heading2 blue'>
                    <p data-aos='fade-up' data-aos-duration='800'>
                      Our Services
                    </p>
                    <h2 data-aos='fade-up' data-aos-duration='1000'>
                      BOOST YOUR BUSINESS WITH <br /> OUR COMPREHENSIVE RANGE OF
                      <br /> TECHNOLOGY SOLUTIONS AND SERVICES.
                    </h2>
                  </div>
                  <ul>
                    <li data-aos='fade-up' data-aos-duration='700'>
                      <div className='card-img chatboat-img'>
                        <img alt='chatboat' src={service1} />
                      </div>
                      <div className='card-content'>
                        <h3>Cloud Transformation</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/cloud-transformation'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='900'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service2} />
                      </div>
                      <div className='card-content'>
                        <h3>DevOps as a Service</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/devOps-service'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1000'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service3} />
                      </div>
                      <div className='card-content'>
                        <h3>Data & Analytics</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/data-analytics'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service4} />
                      </div>
                      <div className='card-content'>
                        <h3>AI & ML</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/ai-ml'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service5} />
                      </div>
                      <div className='card-content'>
                        <h3>Design & UX</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/desing-ux'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service6} />
                      </div>
                      <div className='card-content'>
                        <h3>Data Processing</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/data-processing'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service7} />
                      </div>
                      <div className='card-content'>
                        <h3>Product Development</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/product-development'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service8} />
                      </div>
                      <div className='card-content'>
                        <h3>Software Development</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/software-development'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service9} />
                      </div>
                      <div className='card-content'>
                        <h3>Blockchain Services</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/blockchain-services'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service10} />
                      </div>
                      <div className='card-content'>
                        <h3>Digital Marketing</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/digital-marketing'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service11} />
                      </div>
                      <div className='card-content'>
                        <h3>QA & Testing</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/qa-testing'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos='fade-up' data-aos-duration='1100'>
                      <div className='card-img'>
                        <img alt='chatboat' src={service12} />
                      </div>
                      <div className='card-content'>
                        <h3>Talent as a Service</h3>
                      </div>
                      <div className='card-footer'>
                        <Link to='/service/talent-service'>
                          <Button>
                            <SvgIcon
                              name='arrow-right'
                              viewbox='0 0 20.557 11.759'
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className='about-section'>
          <Container>
            <Row className='about-row'>
              <Col lg='6' className='about-content'>
                <label data-aos='fade-right' data-aos-duration='800'>
                  Expertise & Innovation
                </label>
                <h2 data-aos='fade-right' data-aos-duration='900'>
                  Expertise Meets Innovation
                </h2>
                <p data-aos='fade-right' data-aos-duration='1000'>
                  Expertise meets innovation in our solutions, offering
                  cutting-edge strategies rooted in seasoned wisdom and
                  adaptability.
                </p>
              </Col>
              <Col lg='6' className='about-img'>
                <div className='about-img-inner'>
                  {/* <img
                    data-aos="fade-left"
                    data-aos-duration="1400"
                    className="img1"
                    alt="Collaboration"
                    src="/static/bot2do/shadowww.png"
                  /> */}
                  <img
                    data-aos='fade-left'
                    data-aos-duration='800'
                    className='img2'
                    alt='Collaboration'
                    src={Image1}
                    height='350'
                  />
                </div>
              </Col>
            </Row>
            <Row className='about-row about-row2'>
              <Col lg='6' className='about-img'>
                <div className='about-img-inner'>
                  {/* <img
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    className="img1"
                    alt="Edit and Update"
                    src="/static/bot2do/customised-solutions.svg"
                  /> */}
                  <img
                    data-aos='fade-right'
                    data-aos-duration='1400'
                    className='img2'
                    alt='Edit and Update'
                    src={Image2}
                    height='350'
                  />
                </div>
              </Col>
              <Col lg='6' className='about-content'>
                <label data-aos='fade-left' data-aos-duration='800'>
                  Customized Services
                </label>
                <h2 data-aos='fade-right' data-aos-duration='900'>
                  Tailored Solutions, Every Time
                </h2>
                <p data-aos='fade-right' data-aos-duration='1000'>
                  We craft bespoke strategies, ensuring our solutions fit your
                  unique challenges like a glove.
                </p>
              </Col>
            </Row>
            <Row className='about-row'>
              <Col lg='6' className='about-content'>
                <label data-aos='fade-right' data-aos-duration='800'>
                  Partnership & Support
                </label>
                <h2 data-aos='fade-right' data-aos-duration='900'>
                  Reliable Collaboration
                </h2>
                <p data-aos='fade-right' data-aos-duration='1000'>
                  Partnering with you, we offer unwavering support, transparent
                  communication, and a dedication to your success.
                </p>
              </Col>
              <Col lg='6' className='about-img'>
                <div className='about-img-inner'>
                  {/* <img
                    data-aos="fade-left"
                    data-aos-duration="1400"
                    className="img1"
                    alt="Collaboration"
                    src="/static/bot2do/shadowww.png"
                  /> */}
                  <img
                    data-aos='fade-left'
                    data-aos-duration='800'
                    className='img2'
                    alt='Collaboration'
                    src={Image3}
                    height='350'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='faq-section'>
          <Container>
            <Row>
              <Col>
                <div className='video-row'>
                  <label data-aos='fade-up' data-aos-duration='900'>
                    Contact Us
                  </label>
                  <h1 data-aos='fade-up' data-aos-duration='1000'>
                    SCHEDULE A CONSULTATION <br /> AND DISCOVER HOW WE CAN{' '}
                    <br /> ELEVATE YOUR BUSINESS!
                  </h1>
                  <a
                    href='https://cal.com/bot2do/30min'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button
                      className='get-btn'
                      data-aos='fade-up'
                      data-aos-duration='1000'
                    >
                      Contact Us
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default memo(LandingPage)
