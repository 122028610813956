export const blogs = [
  {
    id: 1,
    category: "Health Care",
    heading: "Streamlining Healthcare Data: A Success Story",
    description:
      "In the ever-changing world of health care, where every byte is precious, data wrangling shouldn’t have to resemble a medical drama. Imagine a scenario where multiple wholesalers and retailers track sales and orders individually, each with their own support systems, all feeding into one chaotic database. You know what happens next: mismatched records, missing fields, wasted time.",
    time: "May 10, 2022 · 3 min",
    image: "/blog/Supply chain blog/Designer (3).png",
    content: {
      "That’s why we’re here.": {
        innercontent:
          "At Bot2Do, we're no strangers to a challenge, especially when it comes to revolutionizing data management in the healthcare industry. Our client faced a daunting task: manual handling of support systems, leading to a maze of data discrepancies and delayed orders. But fear not, for where there's a problem, there's a solution waiting to be crafted. We created a system for more efficient collection and management of information that will bring order to this chaos. Picture this: a single endpoint gathering data from disparate systems, funneling it into one glorious data warehouse. How, you ask? With the magic of API endpoints and Azure Queues, of course. Here's the breakdown: two APIs working in harmony. The first API collects all the data, sorting through the clutter and filtering out duplicates. It then sends this refined data to the Azure Queue, where a clever little function works its magic, validating the data against our pristine database. The result? A symphony of streamlined processes and flawless data flow.",
      },
      "But what about the results, you ask? ": {
        innercontent: `We reduced manual work by 40%, freeing up valuable time for more important tasks. Also, the cherry on top? We improved data quality by 80%, which means that each piece of information is as clean as the day it was born.
          
          But wait, there’s more! Our order tracking capabilities have been upgraded which led to a 100% increase in customer satisfaction.
  
          Real-time data exchange? Check.
          Improved workflow efficiency? Double check.
  `,
      },
      Conclusion: {
        innercontent: `In conclusion, at Bot2Do we do not just solve problems; we revolutionize industries. So why settle for chaos when you can have clarity? Join us on the journey to a brighter, more efficient future!`,
      },
    },
  },
  {
    id: 2,
    category: "Supply Chain",
    heading:
      "Streamlining Supply Chains: How Bot2Do Revolutionized Real-time Inventory Management",
    description:
      "Explore how Bot2Do revolutionized supply chain management with their Real-time Inventory Management System, boosting efficiency, accuracy, and profitability for their client.",
    time: "September 15, 2022 · 4 min",
    image: "/blog/AI images blog one/image5.jpg",
    content: {
      Introduction: {
        innercontent: `In the fast-paced world of supply chain management, efficiency is key. Imagine the frustration of managing warehouses globally, juggling inventory for various industries, all while striving to stay ahead of the curve. This was the situation faced by our customer, a major player in the supply chain space. They were committed to using technology to drive operational efficiency but needed help creating a system that would improve visibility into inventory and streamline stock level management across their organization.`,
      },
      "Client Challenge": {
        innercontent: `Our client's journey began with a simple goal: real-time information about what products were available and where; faster response times to out-of-stock situations; better tracking of items within each warehouse’s four walls. With a comprehensive SWOT analysis laying bare the challenges and opportunities, we set out to deliver a solution that would not just meet but exceed expectations.`,
      },
      Solution: {
        innercontent: `Drawing on our expertise in Java microservices and Python development, coupled with the robust infrastructure of Google Cloud Platform, we built them a one-of-a-kind Real-time Inventory Management System, that eliminated guesswork from the equation forever after completion. We automated workflows with container orchestration and serverless components, ensuring that inventory operations are efficient 24/7.`,
      },
      "Key Features": {
        innercontent: `Our solution had some key features which we designed to give the client more control over their inventory than ever before:
          <ol className="text-black font-inter text-sm md:text-base lg:text-lg xl:text-xl mb-6">
            <li><strong>Visibility of Real-Time Inventory:</strong> You get immediate access to information – no more guessing or wondering.</li>
            <li><strong>Alerts for Items Going Out of Stock:</strong> Stay proactive by receiving notifications about potential stockouts early enough to prevent any interruptions in business activity.</li>
            <li><strong>Efficient Item Tracking:</strong> Even in the most complex warehouses, it is always easy to track items down.</li>
            <li><strong>Scalability Plus Flexibility:</strong> Keep growing as much as you want without skipping a beat while being able to adjust easily when needs change.</li>
          </ol>
          `,
      },
      Results: {
        innercontent: `The results were nothing short of remarkable. Our client experienced a significant 40% increase in inventory accuracy, coupled with a30Y% improvement in stock availability. Warehouse efficiency soared, resulting in a 70% reduction in order fulfillment times.`,
      },
      Conclusion: {
        innercontent: `At Bot2Do, we know what innovation can do and we have seen how powerful technology can be. Our partnership with our client is a testament to that belief, showcasing how technology, when wielded with purpose and precision, can transform your problems into profits!`,
      },
    },
  },
  {
    id: 3,
    category: "Retail",
    heading:
      "Improving Invoice Tracking: How Bot2Do Revolutionized Invoice Tracking",
    description:
      "Explore how Bot2Do revolutionized invoice tracking with their cloud storage and automated drop-down features, boosting efficiency, accuracy, and profitability for their client.",
    time: "February 9, 2023 · 4 min",
    image: "/Blogs/1.webp",
    content: {
      "Importance of optimzation of invoice tracking and invoice apps": {
        innercontent: `In the fast-paced world of business, every click counts. Invoices, though small in size, carry immense weight in ensuring smooth transactions and maintaining financial health. For companies, optimizing invoice tracking and management isn't just a matter of convenience; it's a strategic imperative for efficiency and growth.`,
      },
      "Why does it matter?": {
        innercontent: `In the relentless pursuit of operational excellence, every second saved translates into increased productivity and profitability. Bot2Do understands this crucial aspect, which is why we've engineered an invoice app that streamlines the entire process, reducing it from a cumbersome 5-6 clicks to just a few, while also automating key features like date and numbering. The result? Enhanced productivity, smoother operations, and happier stakeholders.`,
      },
      "How did we build it?": {
        innercontent: `<ol className="text-black font-inter text-sm md:text-base lg:text-lg xl:text-xl mb-6">
            <li>At Bot2Do, innovation is our cornerstone. We've meticulously crafted our invoice app, leveraging cutting-edge technology and user-centric design principles.</li>
            <li>The addition of automated drop-down features has not only accelerated the invoicing process but has also made it more intuitive and user-friendly.</li>
            <li>Furthermore, our implementation of cloud storage has been pivotal in handling vast volumes of data seamlessly, ensuring zero data loss and unparalleled peace of mind.</li>
          </ol>
          `,
      },
      "Key Differentiators": {
        innercontent: `<ol className="text-black font-inter text-sm md:text-base lg:text-lg xl:text-xl mb-6">
            <li>What sets Bot2Do apart is our relentless pursuit of perfection.</li>
            <li>We've processed up to 20,000 invoices, totaling 30 lakh, without a hitch, thanks to our robust storage system.</li>
            <li>Our app's automated drop-down features have slashed invoice completion time from 30 minutes to just 5-10 minutes, marking a paradigm shift in efficiency.</li>
            <li>Moreover, our global resources ensure that our solutions are accessible and scalable, regardless of geographical boundaries.</li>
          </ol>
          `,
      },
      "Success Rate": {
        innercontent: `The proof of our prowess lies in the numbers – 20,000 users, 30 lakh invoices, and zero issues. </br>
          Our storage system acts as a fortress, safeguarding precious data and instilling confidence in our clients. The efficiency gains achieved through our app have not only transformed invoicing but have also elevated overall operational excellence, propelling businesses towards greater success.
          `,
      },
      "Why you can rely on us": {
        innercontent: `<ol className="text-black font-inter text-sm md:text-base lg:text-lg xl:text-xl mb-6">
            <li>Reliability is our hallmark.</li>
            <li>Bot2Do doesn't just offer solutions; we offer peace of mind.</li>
            <li>Our commitment to excellence is unwavering, and our track record speaks for itself.</li>
            <li>With Bot2Do by your side, you can rest assured that your invoicing needs are in capable hands.</li>
            <li>Trust is earned, and at Bot2Do, we've earned it through integrity, transparency, and a steadfast dedication to exceeding expectations.</li>
          </ol>
          `,
      },
      "Beyond Invoicing": {
        innercontent: `Our expertise doesn't end with invoicing. Bot2Do offers a myriad of IT solutions tailored to meet the diverse needs of modern businesses. From project management to data analytics, we're your trusted partner in navigating the digital landscape.`,
      },
      "Contact Us": {
        innercontent: `Bot2Do isn't just revolutionizing invoicing; we're reshaping the future of business operations. Join us on this transformative journey, and experience the Bot2Do advantage firsthand. Your business deserves nothing less. </br>
          You can contact us at <b><em>'admin@Bot2Dotek.com'</em></b>`,
      },
    },
  },
  {
    id: 4,
    category: "Health Care",
    heading: "Streamlining Healthcare: A Tech-Powered Revolution",
    description:
      "Let's dive into a groundbreaking success story of how technology is revolutionizing healthcare delivery. Let's uncover we helped a leading US-based healthcare organization to overcome challenges, embrace innovation, and achieve remarkable results.",
    time: "April 23, 2023 · 4 min",
    image: "/Blogs/HCM Manimegalai Website Blog (1).webp",
    content: {
      "The Challenge": {
        innercontent: `Concerned about complicated old-fashioned processes and manual workflows, our healthcare hero wanted to improve patient care and operational efficiency. But how? By automation of course!`,
      },
      "The Solution": {
        innercontent: `Enter Docker, Kubernetes, AWS, Node.js, Python, and Jenkins—the dream team of automation. With these cutting-edge technologies, it built a seamless CI/CD pipeline, boosted scalability with AWS, and crafted automated workflows for smoother operations.
          </br>
          <em>But how exactly did these technologies help?</em>
          <ol className="text-black font-inter text-sm md:text-base lg:text-lg xl:text-xl mb-6">
            <li><b>Docker:</b> Simplified app deployment, like magic containers</li>
            <li><b>Kubernetes:</b> Orchestrated seamless scaling and management.</li>
            <li><b>AWS:</b> Provided reliable and scalable infrastructure in the cloud.</li>
            <li><b>Node.js:</b> Scripted automation for swift workflows.</li>
            <li><b>Python:</b> Automated tasks with ease and precision.</li>
            <li><b>Jenkins:</b> Ensured smooth integration and deployment of code changes.</li>
          </ol>`,
      },
      "The Result: From Hurdles to Milestones": {
        innercontent: `The payoff? A 20% acceleration in workflows, a 15% decrease in errors, and a whopping 20% increase in operational efficiency! Plus, with cloud security at 100%, and projected cost savings of thousands of dollars annually, the future looks brighter than ever.`,
      },
      "What's Next?": {
        innercontent: `This success story isn't just about numbers—it's about transforming lives. By embracing innovation, our healthcare hero is setting the stage for a future where every patient receives top-notch care, every process runs smoothly, and every dollar is well spent. Join us as we continue to push boundaries, break barriers, and redefine what's possible in healthcare. Together, let's use technologies at our benefit and grow your business!`,
      },
    },
  },
  {
    id: 5,
    category: "Telecommunications",
    heading: "How Tech Transformed an Auditing Firm",
    description:
      "In the bustling world of auditing, every second counts. For a top audit firm in South India, managing invoices was becoming a headache. The old way – lots of clicks, manual handling and tons of paperwork – just wasn’t cutting it anymore. They needed a change, something that would make their lives easier and their work more efficient.",
    time: "July 7, 2023 · 5 min",
    image: "/Blogs/Manimegalai Audit Invoice Website Blog  (1).webp",
    content: {
      "A call for change": {
        innercontent: `Here comes the technology to the rescue. By leveraging innovative tools and platforms, this audit firm chose to revolutionize their invoicing process. Instead of drowning in paperwork they went for our cutting-edge technologies to simplify their workload and increase productivity.`,
      },
      "Introducing the Invoice App": {
        innercontent: `We brought in a new invoice app to make their lives better and easier. The firm's employees found themselves free from the shackles of manual processes. Creating invoices became so simple by introducing features like automatic date insertion and numbering – just some few clicks were enough. Furthermore, drop-down menus made it even smoother making it possible to complete an invoice within five to ten minutes instead of 30 minutes.`,
      },
      "What happened next?": {
        innercontent: `The transformation didn't stop there. Recognizing the need for secure and efficient data management, the firm embraced cloud storage technology. Now, with the ability to process up to 20,000 invoices without breaking a sweat, they had the peace of mind knowing that their data was safe, secure, and easily accessible whenever they needed it.`,
      },
      "Handling workloads with ease": {
        innercontent: `With their newfound efficiency and productivity, the auditing firm was able to handle their workload with ease, freeing up valuable time to focus on more important tasks. Clients were happier, employees were more satisfied, and the firm was well-positioned for future growth and success.`,
      },
      "Why the change is necessary?": {
        innercontent: `In the ever-evolving world of auditing, staying ahead of the curve is essential. By embracing technology and leveraging the power of innovative tools, this auditing firm has not only transformed their invoicing process – they've revolutionized the way they do business. With our services & technology as your ally, the possibilities are endless, and the future is brighter than ever before. `,
      },
    },
  },
];
