import React from 'react'
import { useParams } from 'react-router-dom'

const CaseStudy = (  ) => {
  const { id } = useParams();

  console.log("id in blog", id)
  return (
    <div>Case Study {id}</div>
  )
}

export default CaseStudy