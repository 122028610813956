import React, { useState } from 'react'
import './index.less'

const TestimonialSlideShow = () => {
  // const [testimonialsClassName, setTestimonialsClassName] = useState(
  //   'testimonials-slideshow animation'
  // )
  // const animationStop = () => {
  //   setTestimonialsClassName('testimonials-slideshow')
  // }
  // const animationStart = () => {
  //   setTestimonialsClassName('testimonials-slideshow animation')
  // }

  return (
    <div className='testimonials-slideshow-wrapper'>
      <div className={'testimonials-slideshow'}>
        <div class='snip1157'>
          <blockquote>
          Bot2Do Technologies expertise in data management, our company has experienced a significant improvement in 
          order tracking accuracy. The implementation of their system has not only reduced manual work but has also provided us 
          with clean and reliable data, empowering us to make better business decisions.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Yves Mekongo </h4>
              <span>Procurement Manager</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
          Bot2Do Technologies' AI-Driven System heralds a new era in procurement strategies. By integrating state-of-the-art 
          technologies like NodeJS microservices and Python, Bot2Do Technologies facilitates seamless decision-making and real-time 
          collaboration. 
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Marc Jarrett</h4>
              <span>Head of Growth, Emjay Consultancy Ltd</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
          We owes a debt of gratitude to Bot2Do Technologies for their pivotal role in crafting our 
          Realtime Order Management System. Their team's unwavering commitment and technical acumen facilitated the seamless 
          fusion of cutting-edge technologies, ushering in a transformative solution.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Junaid Ahmed</h4>
              <span>CEO, KH Exports</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'testimonials-slideshow'}>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='snip1157'>
          <blockquote>
            Calvin: You know sometimes when I'm talking, my words can't keep up
            with my thoughts... I wonder why we think faster than we speak.
            Hobbes: Probably so we can think twice.
            <div class='arrow'></div>
          </blockquote>
          <div className='author-details-wrapper'>
            <div className='author-img'>
              <img
                src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg'
                alt='sq-sample3'
              />
            </div>
            <div class='author'>
              <h4>Pelican Steve </h4>
              <span>CEO, EthalPLC</span>
              <div className='author-rating'>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
                <svg
                  class=' active'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                  style={{ width: '1em', height: '1em' }}
                >
                  <path
                    fill='#FECE00'
                    d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialSlideShow
