import React from "react";
import { useParams } from "react-router-dom";
import BlogInfo from "./BlogInfo/BlogInfo";
import { Container, Footer } from "../../../components/common";
import MainHeader from "../../../components/common/MainHeader";
import BlogArticle from "./BlogArticle/BlogArticle";
import Carousel from "./Carousel/Carousel";

const SubBlog = () => {
  const { id } = useParams();
  console.log("id in blog", id);

  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section templates-page services">
        <section className="banner-section">
          <Container>
            <BlogInfo subBlogId={id} />
            <Carousel subBlogId={id} />
            <BlogArticle subBlogId={id} />
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default SubBlog;
