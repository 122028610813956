import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Carousel.less";

const IMAGES_DATA1 = [
  {
    id: 0,
    src: "/blog/AI images blog one/image1.jpg",
  },
  {
    id: 1,
    src: "/blog/AI images blog one/image2.jpg",
  },
  {
    id: 2,
    src: "/blog/AI images blog one/image3.jpg",
  },
  {
    id: 3,
    src: "/blog/AI images blog one/image4.jpg",
  },
  {
    id: 4,
    src: "/blog/AI images blog one/image5.jpg",
  },
  {
    id: 5,
    src: "/blog/AI images blog one/image6.jpg",
  },
  {
    id: 6,
    src: "/blog/AI images blog one/image7.jpg",
  },
  {
    id: 7,
    src: "/blog/AI images blog one/image8.jpg",
  },
];

const IMAGES_DATA2 = [
  {
    id: 0,
    src: "/blog/Supply chain blog/Designer (1).png",
  },
  {
    id: 1,
    src: "/blog/Supply chain blog/Designer (2).png",
  },
  {
    id: 2,
    src: "/blog/Supply chain blog/Designer (3).png",
  },
  {
    id: 3,
    src: "/blog/Supply chain blog/Designer (4).png",
  },
  {
    id: 4,
    src: "/blog/Supply chain blog/Designer (5).png",
  },
  {
    id: 5,
    src: "/blog/Supply chain blog/Designer (6).png",
  },
];

const IMAGES_DATA3 = [
  {
    id: 0,
    src: "/Blogs/1.webp",
  },
  {
    id: 1,
    src: "/Blogs/2.webp",
  },
  {
    id: 2,
    src: "/Blogs/3.webp",
  },
  {
    id: 3,
    src: "/Blogs/4.webp",
  },
  {
    id: 4,
    src: "/Blogs/1.webp",
  },
  {
    id: 5,
    src: "/Blogs/2.webp",
  },
];

const IMAGES_DATA4 = [
  {
    id: 0,
    src: "/Blogs/HCM Manimegalai Website Blog (1).webp",
  },
  {
    id: 1,
    src: "/Blogs/HCM Manimegalai Website Blog (2).webp",
  },
  {
    id: 2,
    src: "/Blogs/HCM Manimegalai Website Blog (3).webp",
  },
  {
    id: 3,
    src: "/Blogs/HCM Manimegalai Website Blog (4).webp",
  },
  {
    id: 4,
    src: "/Blogs/HCM Manimegalai Website Blog (5).webp",
  },
  {
    id: 5,
    src: "/Blogs/HCM Manimegalai Website Blog (6).webp",
  },
];

const IMAGES_DATA5 = [
  {
    id: 0,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (1).webp",
  },
  {
    id: 1,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (2).webp",
  },
  {
    id: 2,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (3).webp",
  },
  {
    id: 3,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (4).webp",
  },
  {
    id: 4,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (5).webp",
  },
  {
    id: 5,
    src: "/Blogs/Manimegalai Audit Invoice Website Blog  (5).webp",
  },
];

const IMAGES_MAP = {
  1: IMAGES_DATA1,
  2: IMAGES_DATA2,
  3: IMAGES_DATA3,
  4: IMAGES_DATA4,
  5: IMAGES_DATA5,
};

export default function Carousel({ subBlogId }) {
  const [images, setImages] = useState(IMAGES_MAP[subBlogId] || IMAGES_DATA1);
  const [intervalId, setIntervalId] = useState(null);
  const handleMove = (direction) => {
    const imgArrCopy = [...images];
    if (direction > 0) {
      const firstItem = imgArrCopy.shift();
      imgArrCopy.push({ ...firstItem, id: Math.random() });
    } else {
      const lastItem = imgArrCopy.pop();
      imgArrCopy.unshift({ ...lastItem, id: Math.random() });
    }
    setImages(imgArrCopy);
  };

  const variants = {
    active: { x: "calc(-50% + 0px)", width: "35rem", scale: 1.3, opacity: 1 },
    level1: (position) => ({
      x: `calc(-50% + ${position * 415}px)`,
      width: "5rem",
      scale: 1.1,
      opacity: 1,
    }),
    level2: (position) => ({
      x: `calc(-50% + ${position * 245}px)`,
      width: "3rem",
      scale: 0.95,
      opacity: 1,
    }),
    level3: (position) => ({
      x: `calc(-50% + ${position * 178}px)`,
      width: "2.5rem",
      scale: 0.7,
      opacity: 1,
    }),
    level4: (position) => ({
      x: `calc(-50% + ${position * 90}px)`,
      width: 0,
      scale: 0.25,
      opacity: 0,
    }),
  };

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const id = setInterval(() => {
      handleMove(1);
    }, 5000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [images]);

  return (
    <div className="carousel-container">
      {images.map((image, i) => {
        let position = 0;
        if (images.length % 2) {
          position = i - (images.length + 1) / 2;
        } else {
          position = i - images.length / 2;
        }
        let imgLevel =
          position === 0
            ? "active"
            : position === -1 || position === 1
            ? "level1"
            : position === -2 || position === 2
            ? "level2"
            : position === -3 || position === 3
            ? "level3"
            : "level4";
        return (
          <motion.div
            key={image.id}
            initial={false}
            className={`carousel-item`}
            animate={imgLevel}
            custom={position}
            variants={variants}
            transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}
          >
            <img
              src={image.src}
              className="carousel-img"
              alt={`Carousel image ${i + 1}`}
            />
          </motion.div>
        );
      })}
      <button
        onClick={() => handleMove(-1)}
        className="carousel-controls carousel-controls-left"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "24px", height: "24px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      <button
        onClick={() => handleMove(1)}
        className="carousel-controls carousel-controls-right"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "24px", height: "24px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
  );
}
