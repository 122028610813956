import React from 'react'
import './ExpandIcon.css'
const ExpandIcon = ({ style }) => {
  return (
    <div className='expand-icon' style={style}>
      <svg
        width='12'
        height='8'
        viewBox='0 0 12 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.00033 0.666016L6.00033 4.66602L10.0003 0.666016L11.3337 1.99935L6.00033 7.33268L0.666992 1.99935L2.00033 0.666016Z'
          fill='currentColor'
        ></path>
      </svg>
    </div>
  )
}

export default ExpandIcon
