import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MegaMenu.less";
import ExpandIcon from "../ExpandIcon";

const SubMenuDetail = ({ detail }) => {
  const { title, info, href, imgSrc, imgAlt } = detail;
  return (
    <a href={href} className="sub-menu-details-link">
      <div className="nav-icon">
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <div className="left-details">
        <span className="menu-title-lg h4">{title}</span>
        <span className="menu-info-lg">{info}</span>
      </div>
    </a>
  );
};

const SubMenuList = ({ item }) => {
  const { title, info, href, imgSrc, imgAlt } = item;
  return (
    <li className="sub-menu-list-item">
      <a href={href} className="sub-menu-list-link">
        <div className="nav-icon">
          <img src={imgSrc} alt={imgAlt} />
        </div>
        <div className="nav-text">
          <span className="nav-title">{title}</span>
          <span className="nav-info">{info}</span>
        </div>
      </a>
    </li>
  );
};

const MegaMenuListItem = ({
  item,
  index,
  currentIndex,
  setCurrentIndex,
  isSubMenuList,
  setHover,
  mobileView,
}) => {
  const { title, href, info, menu } = item;
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const className =
    "mega-menu-list-item " +
    (currentIndex == null
      ? index === 0
        ? " firstActiveMenu"
        : ""
      : index === currentIndex
      ? "openMenu"
      : "");

  const renderMenuItems = () => {
    if ((title === "Our Work" || title === "Blogs") && isSubMenuList) {
      const maxItemsToShow = title === "Our Work" ? 6 : 3; // Maximum items to show based on title
      const itemsToShow = showMore ? menu : menu.slice(0, maxItemsToShow); // Show the specified number of items
      return (
        <>
          {itemsToShow.map((menuListItem, idx) => (
            <SubMenuList key={idx} item={menuListItem} />
          ))}
          {title === "Blogs" && menu.length > 3 && !showMore && (
            <button
              onClick={() => navigate("/our-work/blog")}
              className="view-more-button"
            >
              View More
            </button>
          )}
          {title === "Our Work" && menu.length > 5 && !showMore && (
            <button
              onClick={() => setShowMore(true)}
              className="view-more-button"
            >
              View More
            </button>
          )}
        </>
      );
    }
    return menu.map((menuListItem, idx) => (
      <SubMenuList key={idx} item={menuListItem} />
    ));
  };

  return (
    <li
      className={className}
      onMouseEnter={() => {
        if (mobileView) {
          return;
        }
        setCurrentIndex(index);
      }}
      onClick={() => {
        if (mobileView) {
          if (currentIndex === index) {
            setCurrentIndex(null);
          } else {
            setCurrentIndex(index);
          }
        }
      }}
      onMouseLeave={() => {
        if (mobileView) {
          return;
        }
        setCurrentIndex(null);
      }}
    >
      <a href={href} className="mega-menu-list-link">
        <span className="nav-title">{title}</span>
        <span className="nav-info">{info}</span>
        <ExpandIcon />
      </a>
      <div
        className="sub-menu-wrapper"
        onMouseEnter={() => {
          if (mobileView) {
            return;
          }
          setHover(true);
        }}
      >
        {isSubMenuList ? (
          <ul className="sub-menu-list">{renderMenuItems()}</ul>
        ) : (
          <div className="sub-menu-details-content">
            {menu.map((detail, idx) => (
              <SubMenuDetail key={idx} detail={detail} />
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

const MegaMenu = ({
  list,
  hover,
  onMouseEnter,
  onMouseLeave,
  isSubMenuList,
  setHover,
  mobileView,
}) => {
  const [currentIndex, setCurrentIndex] = useState(null);

  return (
    <div
      className={`mega-menu-wrapper ${hover ? "visible" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="navbar-container">
        <div className="mega-menu-inner">
          <div className="mega-menu-left-scroll">
            <ul className="mega-menu-list">
              {list.map((item, index) => (
                <MegaMenuListItem
                  index={index}
                  item={item}
                  key={index}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  isSubMenuList={isSubMenuList}
                  setHover={setHover}
                  mobileView={mobileView}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;

/*
{/* <li className='mega-menu-list-item undefined'>
                <a href='' className='mega-menu-list-link'>
                  <span className='nav-title'>
                    Product Engineering And Development
                  </span>
                  <span className='nav-info'>
                    Turn ideas into powerful digital products
                  </span>
                  <ExpandIcon />
                </a>
                <div className='sub-menu-wrapper'>
                  <div className='sub-menu-details-content'>
                    <a href='' className='sub-menu-details-link'>
                      <div className='nav-icon'>
                        <img
                          src='/images/services/product-eng/product-eng-and-development.svg'
                          alt='digital-product'
                        />
                      </div>
                      <div className='left-details'>
                        <span className='menu-title-lg h4'>
                          Product Engineering And Development
                        </span>
                        <span className='menu-info-lg'>
                          Simform acts as a strategic software engineering
                          partner to build products designed to cater the unique
                          requirements of each client. From rapid prototyping to
                          iterative development, we help you validate your idea
                          and make it a reality.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li className='mega-menu-list-item undefined'>
                <a href='' className='mega-menu-list-link'>
                  <span className='nav-title'>
                    Product Engineering And Development
                  </span>
                  <span className='nav-info'>
                    Turn ideas into powerful digital products
                  </span>
                  <ExpandIcon />
                </a>
                <div className='sub-menu-wrapper'>
                  <div className='sub-menu-details-content'>
                    <a href='' className='sub-menu-details-link'>
                      <div className='nav-icon'>
                        <img
                          src='/images/services/product-eng/product-eng-and-development.svg'
                          alt='digital-product'
                        />
                      </div>
                      <div className='left-details'>
                        <span className='menu-title-lg h4'>
                          Product Engineering And Development
                        </span>
                        <span className='menu-info-lg'>
                          Simform acts as a strategic software engineering
                          partner to build products designed to cater the unique
                          requirements of each client. From rapid prototyping to
                          iterative development, we help you validate your idea
                          and make it a reality.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li className='mega-menu-list-item undefined'>
                <a href='' className='mega-menu-list-link'>
                  <span className='nav-title'>
                    Product Engineering And Development
                  </span>
                  <span className='nav-info'>
                    Turn ideas into powerful digital products
                  </span>
                  <ExpandIcon />
                </a>
                <div className='sub-menu-wrapper'>
                  <div className='sub-menu-details-content'>
                    <a href='' className='sub-menu-details-link'>
                      <div className='nav-icon'>
                        <img
                          src='/images/services/product-eng/product-eng-and-development.svg'
                          alt='digital-product'
                        />
                      </div>
                      <div className='left-details'>
                        <span className='menu-title-lg h4'>
                          Product Engineering And Development
                        </span>
                        <span className='menu-info-lg'>
                          Simform acts as a strategic software engineering
                          partner to build products designed to cater the unique
                          requirements of each client. From rapid prototyping to
                          iterative development, we help you validate your idea
                          and make it a reality.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li className='mega-menu-list-item undefined'>
                <a href='' className='mega-menu-list-link'>
                  <span className='nav-title'>
                    Product Engineering And Development
                  </span>
                  <span className='nav-info'>
                    Turn ideas into powerful digital products
                  </span>
                  <ExpandIcon />
                </a>
                <div className='sub-menu-wrapper'>
                  <div className='sub-menu-details-content'>
                    <a href='' className='sub-menu-details-link'>
                      <div className='nav-icon'>
                        <img
                          src='/images/services/product-eng/product-eng-and-development.svg'
                          alt='digital-product'
                        />
                      </div>
                      <div className='left-details'>
                        <span className='menu-title-lg h4'>
                          Product Engineering And Development
                        </span>
                        <span className='menu-info-lg'>
                          Simform acts as a strategic software engineering
                          partner to build products designed to cater the unique
                          requirements of each client. From rapid prototyping to
                          iterative development, we help you validate your idea
                          and make it a reality.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li className='mega-menu-list-item undefined'>
                <a href='' className='mega-menu-list-link'>
                  <span className='nav-title'>
                    Product Engineering And Development
                  </span>
                  <span className='nav-info'>
                    Turn ideas into powerful digital products
                  </span>
                  <ExpandIcon />
                </a>
                <div className='sub-menu-wrapper'>
                  <div className='sub-menu-details-content'>
                    <a href='' className='sub-menu-details-link'>
                      <div className='nav-icon'>
                        <img
                          src='/images/services/product-eng/product-eng-and-development.svg'
                          alt='digital-product'
                        />
                      </div>
                      <div className='left-details'>
                        <span className='menu-title-lg h4'>
                          Product Engineering And Development
                        </span>
                        <span className='menu-info-lg'>
                          Simform acts as a strategic software engineering
                          partner to build products designed to cater the unique
                          requirements of each client. From rapid prototyping to
                          iterative development, we help you validate your idea
                          and make it a reality.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>   */
