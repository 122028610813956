import { Container, Footer, Row } from "../../components/common";
import GCP from "../../assets/b/partners/1.png"
import AWS from "../../assets/b/partners/2.png"
import Azure from "../../assets/b/partners/3.png"
import MainHeader from "../../components/common/MainHeader";
import mainImage1 from "../../assets/b/cloud_computing.svg";
import mainImage2 from "../../assets/b/devops.png";
import mainImage3 from "../../assets/b/data-analytics.png";
import mainImage4 from "../../assets/b/ai.png";
import mainImage5 from "../../assets/b/uiux.jpg";
import mainImage6 from "../../assets/b/data-processing.png";

import BritishTelecom from "../../assets/b/companies/1.png"
import FootLocker from "../../assets/b/companies/2.png"
import GXO from "../../assets/b/companies/3.png"
import JnJ from "../../assets/b/companies/4.png"
import Kavida from "../../assets/b/companies/6.png"
import Lululemon from "../../assets/b/companies/7.png"
import StitchFix from "../../assets/b/companies/8.png"

import mainImage7 from "../../assets/b/product-development.png";
import mainImage8 from "../../assets/b/software-development.png";
import mainImage9 from "../../assets/b/blockchain.png";
import mainImage10 from "../../assets/b/digital-marketing-header.png";
import mainImage11 from "../../assets/b/qa-main.png";
import mainImage12 from "../../assets/b/talnet.png"
import service1FlexImage1 from "../../assets/b/cloud-service.png";
import service1FlexImage2 from "../../assets/b/serverless.png";
import service1FlexImage3 from "../../assets/b/microservice.png";
import service1FlexImage4 from "../../assets/b/kubernetes.png";
import service1FlexImage5 from "../../assets/b/migration.png";
import service1FlexImage6 from "../../assets/b/cloud-architecture.png";
import service2FlexImage1 from "../../assets/b/devops-consulting.png";
import service2FlexImage2 from "../../assets/b/cloud-infrastructure.png";
import service2FlexImage3 from "../../assets/b/cicd.png";
import service2FlexImage4 from "../../assets/b/containerization.png";
import service2FlexImage5 from "../../assets/b/infra.png";
import service2FlexImage6 from "../../assets/b/cloud-security.png";
import service3FlexImage1 from "../../assets/b/data-consulting.png";
import service3FlexImage2 from "../../assets/b/data-storage.png";
import service3FlexImage3 from "../../assets/b/dashboard.png";
import service3FlexImage4 from "../../assets/b/dashboard-optimization.png";
import service3FlexImage5 from "../../assets/b/business-intelligence.png";
import service3FlexImage6 from "../../assets/b/analytics.png";
import service4FlexImage1 from "../../assets/b/ai-consulting.png";
import service4FlexImage2 from "../../assets/b/machine-learning.png";
import service4FlexImage3 from "../../assets/b/ai-apps.png";
import service4FlexImage4 from "../../assets/b/natural-language-processing.png";
import service4FlexImage5 from "../../assets/b/data-support.png";
import service4FlexImage6 from "../../assets/b/ai-optimization.png";
import service5FlexImage1 from "../../assets/b/brand-design.png";
import service5FlexImage2 from "../../assets/b/web-design.png";
import service5FlexImage3 from "../../assets/b/3d-design.png";
import service5FlexImage4 from "../../assets/b/content-management.png";
import service5FlexImage5 from "../../assets/b/video-editor.png";
import service5FlexImage6 from "../../assets/b/digital-marketing.png";
import service6FlexImage1 from "../../assets/b/data-warehouse.png";
import service6FlexImage2 from "../../assets/b/data-consulting.png";
import service6FlexImage3 from "../../assets/b/data-cleaning.png";
import service6FlexImage4 from "../../assets/b/data-integration.png";
import service6FlexImage5 from "../../assets/b/data-transformation.png";
import service6FlexImage6 from "../../assets/b/data-migration.png";
import service7FlexImage1 from "../../assets/b/conceptualization.png";
import service7FlexImage2 from "../../assets/b/brand-design.png";
import service7FlexImage3 from "../../assets/b/prototyping.png";
import service7FlexImage4 from "../../assets/b/development.png";
import service7FlexImage5 from "../../assets/b/qa.png";
import service7FlexImage6 from "../../assets/b/support.png";
import service8FlexImage1 from "../../assets/b/web-development.png";
import service8FlexImage2 from "../../assets/b/software.png";
import service8FlexImage3 from "../../assets/b/enterprise-solutions.png";
import service8FlexImage4 from "../../assets/b/integration.png";
import service8FlexImage5 from "../../assets/b/consulting.png";
import service8FlexImage6 from "../../assets/b/app-development.png";
import service9FlexImage1 from "../../assets/b/ethereum.png";
import service9FlexImage2 from "../../assets/b/smart-contract.png";
import service9FlexImage3 from "../../assets/b/poc.png";
import service9FlexImage4 from "../../assets/b/wallet-development.png";
import service9FlexImage5 from "../../assets/b/blockchain-consulting.png";
import service9FlexImage6 from "../../assets/b/cloud-blockchain.png";
import service10FlexImage1 from "../../assets/b/seo.png";
import service10FlexImage2 from "../../assets/b/web-analytics.png";
import service10FlexImage3 from "../../assets/b/marketing-automation.png";
import service10FlexImage4 from "../../assets/b/social-media.png";
import service10FlexImage5 from "../../assets/b/content-marketing.png";
import service10FlexImage6 from "../../assets/b/email-marketing.png";
import service11FlexImage1 from "../../assets/b/test.png";
import service11FlexImage2 from "../../assets/b/functional-testing.png";
import service11FlexImage3 from "../../assets/b/non-functional-test.png";
import service11FlexImage4 from "../../assets/b/data-testing.png";
import service11FlexImage5 from "../../assets/b/next-gen-testing.png";
import service11FlexImage6 from "../../assets/b/api-testing.png";
import service12FlexImage1 from "../../assets/b/staff-augmentation.png";
import service12FlexImage2 from "../../assets/b/project-staffing.png";
import service12FlexImage3 from "../../assets/b/technical-support.png";
import service12FlexImage4 from "../../assets/b/resource-management.png";
import service12FlexImage5 from "../../assets/b/strategic-partnership.png";
import service12FlexImage6 from "../../assets/b/skill-development.png";
import React from "react";
import "./index.less";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Clientele from "../../assets/b/companies/Clientile.png"


const SERVICES = {
  "cloud-transformation": {
    title: "Cloud Transformation",
    content: "At Bot2Do Technologies, we get the hurdles your organization may face with old systems and the desire for smooth growth. With careful planning and execution, we make sure your shift to top cloud platforms like AWS, Azure, or Google Cloud is seamless. It's more than a tech upgrade—it's a transformative process giving your business better flexibility, scalability, and cost-effectiveness.",
    image: mainImage1,
    midContent1: "Bot2Do provides a comprehensive range of cloud development services designed to assist organizations in migrating and optimizing their workloads and applications for enhanced and efficient utilization of the cloud.",
    midContent2: "Demonstrated expertise in leading the transition towards cloud technology.",
    midContent3: "Bot2Do's cloud development services enable organizations to swiftly innovate, adapt, and expand in response to evolving market demands. Our skilled cloud architects and developers utilize cutting-edge technology to ensure outstanding performance across a diverse array of solutions.",
    list1: "Seamless transitions to the cloud",
    list2: "Secure cloud-native solutions",
    list3: "Agile Integration Strategies",
    list4: "Future-gen, scalable solutions",
    list5: "Seasoned cloud consulting experts",
    gridHeader: "Our Cloud Expertise",
    gridContent: "Deliver exceptional digital experiences without burning a hole in your pocket! Leverage our suite of cloud development services crafted to address and effortlessly handle your cloud-related operations and deployment needs.",
    flexContent1Header: "Cloud Development",
    flexContent1Content: "Accelerate your business results by harnessing the cloud-native app development services offered by bot2do. Our strong expertise in cloud engineering and diverse range of services empower your organization to maximize the benefits of the cloud across its various manifestations.",
    flexContent1Image: service1FlexImage1,
    flexContent2Header: "Serverless app development",
    flexContent2Content: "Experience automatic scalability and lower operational expenses. Eliminate concerns about backend provisioning or maintenance, allowing you to concentrate solely on providing an outstanding user experience to your customers.",
    flexContent2Image: service1FlexImage2,
    flexContent3Header: "Microservices Architecture",
    flexContent3Content: " Harness the advantages of on-demand scalability. Utilize our comprehensive microservices architecture and deployment services to transform your application into automated and scalable microservices, enabling the delivery of a smooth user experience.",
    flexContent3Image: service1FlexImage3,
    flexContent4Header: "Kubernetes Consulting",
    flexContent4Content: "Elevate your application’s performance by leveraging the best-in-class Kubernetes consulting services. We help you harness the power of Kubernetes' features like deployment patterns, canary deployment, failover monitoring system, etc.",
    flexContent4Image: service1FlexImage4,
    flexContent5Header: "Cloud Migration Consulting",
    flexContent5Content: "Bot2Do's cloud migration services involve evaluating the current infrastructure and equipping organizations with the necessary tools and methodologies required to migrate their complex and business-critical applications to the desired cloud infrastructure and architecture.",
    flexContent5Image: service1FlexImage5,
    flexContent6Header: "Cloud Architecture and design",
    flexContent6Content: "Leverage cloud capabilities like automation and orchestration with a resilient cloud platform to innovate safely, implement the latest cloud capabilities, and minimize system downtime with proven cloud architecture design services and tested strategies.",
    flexContent6Image: service1FlexImage6,
  },
  "devOps-service": {
    title: "DevOps as a Service",
    content: "Recognizing the hurdles of disjointed development and operations processes, we offer DevOps as a Service to streamline your software development lifecycle. We understand the importance of efficient collaboration and continuous delivery. Our approach involves deep collaboration with your teams to understand pain points and challenges. Through automation, optimized workflows, and seamless integration, we bridge the gap between development and operations, enabling your team to deliver high-quality software faster. Let us empower your organization with a DevOps culture that responds to your unique challenges and accelerates your time-to-market.",
    image: mainImage2,
    midContent1: "Bot2Do enhances your business with DevOps services, ensuring high-velocity software delivery, cost savings, increased fault tolerance, smoother releases, reduced risks, and simplified maintenance.",
    midContent2: "Driving Cloud Success Through Expert DevOps Leadership.",
    midContent3: "We deliver excellence in DevOps implementations and provide a wide array of services, including consulting, infrastructure management, and infrastructure as a code. Our DevOps experts make use of the right tools, automated processes, and architectural approaches to help you achieve continuous deployment for higher profitability.",
    list1: "Experts in cloud infrastructure management",
    list2: "Cross-functional agile teams",
    list3: "Rigorous quality assurance and testing",
    list4: "Seasoned DevOps experts",
    list5: "Consistent delivery through CI/CD approaches",
    gridHeader: "Our DevOps Services",
    gridContent: "In the dynamic digital landscape, success lies in harmonizing business operations and development initiatives. Choose from the range of DevOps services and get your business the much-needed tactical advantage.",
    flexContent1Header: "DevOps consulting",
    flexContent1Content: "We craft the best DevOps implementation strategies to help streamline workflows, automate CI/CD pipelines, accelerate software delivery, and adhere to the highest development standards. We build you the roadmap to implement DevOps solutions that maximize business efficiency.",
    flexContent1Image: service2FlexImage1,
    flexContent2Header: "Cloud infrastructure management",
    flexContent2Content: "Prepare your cloud infrastructure for an agile delivery model with our DevOps-powered management services. We help you keep downtimes at bay, manage resources efficiently, and scale seamlessly.",
    flexContent2Image: service2FlexImage2,
    flexContent3Header: "CI/CD services",
    flexContent3Content: "Adapt to changing market trends and user demands using DevOps methodologies that help you deploy and manage CI/CD pipelines. Roll out innovative solutions at a much greater speed while resolving issues at a higher cadence with our expert CI/CD services.",
    flexContent3Image: service2FlexImage3,
    flexContent4Header: "Containerization services",
    flexContent4Content: "Leverage our DevOps cloud solutions to expedite application deployment across platforms with skilled containerization and orchestration services. We help your business improve performance, streamline software delivery, and reduce security risks.",
    flexContent4Image: service2FlexImage4,
    flexContent5Header: "Infrastructure as code",
    flexContent5Content: "Experience the new-age way of infrastructure management with automated provisioning of IT infrastructure with Infrastructure as code services. It allows developers to manage, operate, and maintain infrastructure with scripts, thus enhancing their productivity.",
    flexContent5Image: service2FlexImage5,
    flexContent6Header: "Enhanced security and protection",
    flexContent6Content: "Ensure enhanced security of user data through our bulletproof security measures that include threat modeling and risk analysis to identify security flaws instantly.",
    flexContent6Image: service2FlexImage6,
  },
  "data-analytics": {
    title: "Data & Analytics",
    content: "Your data holds untapped potential, and at Bot2Do Technologies, we specialize in unlocking it. We understand that making informed decisions requires actionable insights. With our Data & Analytics services, we work closely with your team to comprehend the challenges in harnessing the power of data. Let us be your partner in turning data challenges into opportunities for growth and innovation.",
    image: mainImage3,
    midContent1: "Leverage the power of Data & Analytics to elevate business operations and expand digital outreach. Our solutions enable businesses to tailor customer experiences, refine product strategies, and enhance decision-making capabilities.",
    midContent2: "Turn Your Data into Business Intelligence",
    midContent3: "As a forefront data & analytics company, we specialize in empowering businesses through the utilization of Data Visualizations, Business Analytics. Our holistic approach to business intelligence project implementation ensures the creation of substantial value for your business.",
    list1: "Comprehensive Data Solutions",
    list2: "Advanced Business Intelligence",
    list3: "Cutting-edge Technologies",
    list4: "Reports and Analytics Enhancement",
    list5: "BI Transformation",
    gridHeader: "Our Business Intelligence Services",
    gridContent: "Leveraging cutting-edge technologies such as Power BI, Tableau, Pentaho, MicroStrategy, Highcharts, D3.js, and Informatica, our data and analytics services are designed to deliver comprehensive solutions tailored to your specific needs.",
    flexContent1Header: "Data Strategy and Consulting",
    flexContent1Content: "Craft a winning data strategy with our consulting services. We guide businesses in navigating the data landscape, ensuring effective analytics implementation, insightful decision-making, and maximizing the value of their data assets.",
    flexContent1Image: service3FlexImage1,
    flexContent2Header: "Data Storage Solutions",
    flexContent2Content: "Optimize your data management with our storage solutions. We provide scalable and secure data storage options, ensuring efficient organization, accessibility, and protection of your valuable data assets for advanced analytics and insights.",
    flexContent2Image: service3FlexImage2,
    flexContent3Header: "Dashboard Development",
    flexContent3Content: "We create easy-to-understand reports and dashboards to help you see important information at a glance. Our goal is to make it simple for you to make smart decisions based on clear and visual data.",
    flexContent3Image: service3FlexImage3,
    flexContent4Header: "Dashboard Optimization",
    flexContent4Content: "Enhance your data dashboards with our Optimization services. We fine-tune and improve the performance, design, and usability of your dashboards, ensuring they provide clear, actionable insights for better decision-making and business success.",
    flexContent4Image: service3FlexImage4,
    flexContent5Header: "BI Tools Implementation",
    flexContent5Content: "We specialize in the implementation of cutting-edge Business Intelligence tools. Our expertise lies in setting up and seamlessly integrating advanced BI solutions, empowering organizations to analyze data effectively, derive meaningful insights, and make informed, strategic decisions.",
    flexContent5Image: service3FlexImage5,
    flexContent6Header: "Statistical Analysis & Optimization",
    flexContent6Content: "We specialize in refining your data through Statistical Analysis & Optimization. Using advanced statistical methods, we ensure accurate insights, uncover meaningful patterns, and empower informed decision-making for operational excellence in your business.",
    flexContent6Image: service3FlexImage6,
  },
  "ai-ml": {
    title: "AI & ML",
    content: "We understand the power of AI and machine learning in business transformation. Our AI & ML services address your unique needs, collaborating to identify areas for innovation. From natural language processing to predictive analytics, our solutions solve real-world problems, automating processes, and enhancing decision-making for technological advancement.",
    image: mainImage4,
    midContent1: "At Bot2Do Technology, we help you streamline processes, fortify customer relationships, utilize advanced analytics, and future-proof your business operations using AI/ML.",
    midContent2: "Automate with AI & ML for smart decisions.",
    midContent3: "With our AI & ML consulting and solutions, the potential is limitless. You can extract valuable insights and explore new revenue streams by optimizing the use of your data.",
    list1: "Language Processing and AI Chatbots",
    list2: "Computer Vision Solutions",
    list3: "AI Solutions for E-commerce",
    list4: "Predictive Analysis and Automation",
    list5: "Advanced Technologies",
    gridHeader: "Our AI / ML Services",
    gridContent: "We at Bot2Do Technology deliver sophisticated AI/ML services, driving business optimization through advanced automation, precise data analytics, and strategic decision support. Elevate your enterprise with our expertly crafted intelligent solutions.",
    flexContent1Header: "AI Consulting & Development",
    flexContent1Content: "We specialize in meticulous AI consulting and development services. Our seasoned experts leverage cutting-edge artificial intelligence to refine processes, elevate decision frameworks, and propel your business into a future defined by innovation and efficiency.",
    flexContent1Image: service4FlexImage1,
    flexContent2Header: "Machine Learning Development",
    flexContent2Content: "We offer distinguished machine learning development services. Our adept team engineers intricate algorithms, enabling your business to harness the full potential of machine learning. Elevate your data-driven strategies and achieve unparalleled insights with our expertly crafted solutions.",
    flexContent2Image: service4FlexImage2,
    flexContent3Header: "AI Apps",
    flexContent3Content: "Bot2Do Technology excels in developing AI-powered mobile & web applications, seamlessly integrating intelligent algorithms for superior user experiences. Elevate your digital solutions with our expertise, ensuring cutting-edge AI technologies enhance functionality, and performance across platforms.",
    flexContent3Image: service4FlexImage3,
    flexContent4Header: "Natural Language Processing",
    flexContent4Content: "We implement advanced algorithms for seamless sentiment analysis, information extraction, and conversational interfaces. Elevate your applications with our precise NLP solutions, ensuring a more intuitive and intelligent interaction with users",
    flexContent4Image: service4FlexImage4,
    flexContent5Header: "Data Support for AI/ML",
    flexContent5Content: "Bot2Do Technology provides expert data support for AI/ML, ensuring robust datasets through rigorous cleaning, precise preprocessing, and advanced feature engineering. Our meticulous approach lays the foundation for high-performance models, driving impactful business outcomes with efficiency and precision",
    flexContent5Image: service4FlexImage5,
    flexContent6Header: "AI Optimization",
    flexContent6Content: "Through advanced tuning and fine-tuning methodologies, we elevate accuracy, scalability, and responsiveness, ensuring your AI systems operate at the pinnacle of effectiveness. Count on us for precision in optimizing your artificial intelligence infrastructure.",
    flexContent6Image: service4FlexImage6,
  },
  "desing-ux": {
    title: "Design & UX",
    content: "We understand that creating a lasting impression in the digital landscape requires more than just aesthetics. We begin by understanding your target audience and their pain points. Through collaborative ideation and user-centric design principles, we create interfaces that not only look appealing but also provide an intuitive and enjoyable user experience.",
    image: mainImage5,
    list1: "Crafting Intuitive User Experiences",
    list2: "Implementing Responsive Design Solutions",
    list3: "Prioritizing User-Centric Design Principles",
    list4: "Utilizing Interactive Prototyping Techniques",
    list5: "Creating Microinteractions and Animation",
    midContent1: "Work with cross-functional teams of smart designers and product visionaries to create incredible UX and CX experiences. Bot2Do pairs human-centric design thinking methodologies with industry-led tech expertise to transform user journeys and create incredible digital experience designs.",
    midContent2: "We assist enterprises in transforming digital interactions through a design-oriented, human-centered strategy.",
    midContent3: "Approach Digital experience designs from consumers’ point of view and deliver solutions centered around their problems. Bot2Do helps you negate the risks of broken user flows and unnecessary features by working on ideas and solutions with digital experience design services.",
    gridHeader: "Our UI/UX Services",
    gridContent: "At Bot2Do Technologies, we specialize in crafting intuitive user experiences with responsive design and user-centric principles. Our approach ensures interfaces that resonate, captivate, and deliver exceptional digital interactions.",
    flexContent1Header: "Brand Design",
    flexContent1Content: "Our brand design harmonizes visual elements with user-centric experiences. We prioritize consistency, aesthetics, and usability to create compelling brand identities that seamlessly resonate with your audience's preferences and expectations.",
    flexContent1Image: service5FlexImage1,
    flexContent2Header: "Web Design",
    flexContent2Content: "Navigating our interfaces is an intuitive experience, featuring designs that seamlessly adapt to screens. Elevate your digital presence with our sophisticated web solutions, where simplicity converges with precision for a compelling online journey.",
    flexContent2Image: service5FlexImage2,
    flexContent3Header: "3D Design",
    flexContent3Content: "At Bot2Do Technologies, our 3D design expertise extends across interior, product, and virtual realms. We integrate UI/UX principles to create immersive, visually stunning experiences. Elevate your spaces and products with our precision and innovation in 3D design.",
    flexContent3Image: service5FlexImage3,
    flexContent4Header: "Brand Content Management",
    flexContent4Content: "As a team that understands how consumers view and purchase products, for us the Brand’s content needs to revolve around crafting authentic and consumer-centric experiences.",
    flexContent4Image: service5FlexImage4,
    flexContent5Header: "Video Design",
    flexContent5Content: "We orchestrate video design symphonies, blending UI/UX magic into product narratives, 3D enchantments, and commercial tales. Transform your brand into a visual masterpiece, where every frame tells a compelling story and captivates the audience.",
    flexContent5Image: service5FlexImage5,
    flexContent6Header: "Digital Marketing",
    flexContent6Content: "Bot2Do's Digital Marketing crafts simplicity into impact—SEO brightens visibility, social media sparks connections, content narrates, and PPC propels. Elevate your brand effortlessly with our creative touch",
    flexContent6Image: service5FlexImage6,
  },
  "data-processing": {
    title: "Data Processing",
    content: "The challenges of managing vast amounts of data can be overwhelming, and at Bot2Do Technologies, we're here to help. We understand the complexities of data processing and the critical need for accuracy and accessibility. Let us help you master the art of data processing, ensuring that your data becomes a strategic asset rather than a source of complexity.",
    image: mainImage6,
    list1: "High Quality and Reliability",
    list2: "Faster Turnaround Time",
    list3: "Assured Data Security",
    list4: "Cost Effective Pricing",
    list5: "24 X 7 Accessibility",
    midContent1: "Rising data diversity leads to duplication and challenges in decision-making. In-house teams struggle with the volume, cost, and efficiency. Bot2Do's data processing services offer a solution, enhancing data quality, speeding insights, and facilitating timely, informed business decisions.",
    midContent2: "Data Processing to transform your data into the ideal format for your business needs",
    midContent3: " We assist our clients in assimilating raw data from various datasets, transforming it into a coherent database format, rectifying inconsistencies, ensuring data compatibility across different formats, validating data accuracy, verifying regulatory compliance, and employing ML-powered tools to unearth business-critical insights.",
    gridHeader: "Our Data Processing Services",
    gridContent: "As one of the seasoned data processing companies, we offer comprehensive online data processing solutions that encompass a structured workflow of data collection, data preparation, data entry, data conversion, and analysis.",
    flexContent1Header: "Data lake & warehouse",
    flexContent1Content: "Our team assists you create a scalable platform with the ability to ingest data from multiple sources. Platforms to store, process, and secure large amounts of structured, semistructured, and unstructured data irrespective of the size.",
    flexContent1Image: service6FlexImage1,
    flexContent2Header: "Data consulting and advisory",
    flexContent2Content: "Thriving with your data begins by establishing a robust strategy. Our team of data and analytics consultants assists you in crafting a comprehensive, enduring plan including the necessary people, processes, and technology, enabling you to leverage your data effectively to meet your business objectives.",
    flexContent2Image: service6FlexImage2,
    flexContent3Header: "Data Cleansing",
    flexContent3Content: "Guarantee precision through rigorous data cleansing, systematically eliminating inaccuracies and discrepancies. Enhance data reliability by identifying and rectifying errors, ensuring a dependable foundation for informed decision-making.",
    flexContent3Image: service6FlexImage3,
    flexContent4Header: "Data Integration",
    flexContent4Content: "Effortlessly integrate diverse data sources, forging a cohesive dataset that facilitates streamlined and informed decision-making. This meticulous merging process ensures a comprehensive and accurate foundation, optimizing the reliability of your data for strategic insights and effective business outcomes.",
    flexContent4Image: service6FlexImage4,
    flexContent5Header: "Data Transformation",
    flexContent5Content: "Convert raw data into valuable insights, fostering a deeper understanding for strategic business outcomes. Use the transformed data to create reports and dashboard using your KPIs which helps in making important business decisions.",
    flexContent5Image: service6FlexImage5,
    flexContent6Header: "Data Migration",
    flexContent6Content: "Facilitate smooth transitions with adept professionals, guaranteeing uninterrupted data flow and a seamless transfer of crucial information. Our expertise ensures data integrity, minimizing disruptions and maximizing efficiency in your migration process.",
    flexContent6Image: service6FlexImage6,
  },
  "product-development": {
    title: "Product Development",
    content: "Our Product Development services are tailored to address the challenges of innovation. Through collaborative ideation and meticulous planning, we work with your team to grasp the essence of your vision. From conceptualization to implementation and maintenance, we navigate the complexities of the product development lifecycle.",
    image: mainImage7,
    list1: "Accelerated Time-to-Market",
    list2: "Expert Guidance",
    list3: "Scalable Solutions",
    list4: "User-Centric Approach",
    list5: "Cost-effective scalability",
    midContent1: "Our service for Product Development aims to assist businesses in creating and launching successful products. We collaborate closely with our clients to comprehend their vision, needs, and market demands. Our team of experienced engineers and designers uses cutting-edge tools and methods to develop innovative and user-friendly products.",
    midContent2: "Product Development: We turn ideas into reality",
    midContent3: "With a team of skilled engineers, designers, and strategists, we work closely with you to ensure a smooth product development journey from start to finish. We prioritize quality, scalability, and performance to guarantee that your product not only meets but surpasses your expectations.",
    gridHeader: "Our Product Development Services",
    gridContent: "Transform your ideas into innovative products with Bot2Do's Product Development Services. We help you bring your vision to life by leveraging cutting-edge technology and industry expertise.",
    flexContent1Header: "Ideation & Conceptualization",
    flexContent1Content: "We collaborate with you to generate ideas, define product goals, and create a roadmap for development.",
    flexContent1Image: service7FlexImage1,
    flexContent2Header: "Designing & User Experience",
    flexContent2Content: "Our talented designers craft intuitive and visually appealing user interfaces, ensuring seamless interactions and captivating user experiences.",
    flexContent2Image: service7FlexImage2,
    flexContent3Header: "Prototyping & Testing",
    flexContent3Content: "We rapidly prototype and iterate designs to validate concepts, mitigate risks, and refine product functionality before development.",
    flexContent3Image: service7FlexImage3,
    flexContent4Header: "Full Stack Development",
    flexContent4Content: "Our skilled engineers build robust and scalable software solutions, leveraging the latest technologies and industry best practices.",
    flexContent4Image: service7FlexImage4,
    flexContent5Header: "Testing & QA",
    flexContent5Content: "We rigorously test the product to identify and fix any issues, ensuring its functionality, performance, and security",
    flexContent5Image: service7FlexImage5,
    flexContent6Header: "Deployment & Support",
    flexContent6Content: "Once the product meets your requirements, we assist with its deployment, provide ongoing support, and monitor performance to ensure optimal results.",
    flexContent6Image: service7FlexImage6,
  },
  "software-development": {
    title: "Software Development",
    content: "At Bot2Do Technologies, we comprehend the challenges of staying competitive in a rapidly evolving digital landscape. Our Software Development services are crafted to address your specific business needs. Through a comprehensive approach to development, we deliver robust and scalable software solutions. Whether it's web, mobile, or desktop applications, our goal is to provide tailored software that aligns seamlessly with your organizational objectives, solving your unique challenges and positioning you for sustained success.",
    image: mainImage8,
    list1: "Client-Centric Approach",
    list2: "Scalable Solutions",
    list3: "Security & Compliance",
    list4: "Competitive Edge over competitors",
    list5: "Automation & Improved Efficiency",
    midContent1: "Bot2Do's wide range of functionalities deployed on highly efficient and secure technology as well as a strong mix of business understanding, allows us to craft superior software applications that help you optimize and scale operations, enhance business transparency, and improve growth.",
    midContent2: "Catalyze Business Growth with Bot2Do's Software Development Services",
    midContent3: "We have built a strong reputation in the digital community through our innovative, user-friendly, and productive software applications. If you’re looking to shape the future of your business, begin your journey with Bot2Do.",
    gridHeader: "Our Software Development Services",
    gridContent: "Our inventive mindset, combined with a distinct business advantage, enables us to provide cutting-edge software applications with precision and efficiency. Our commitment to innovation ensures impactful solutions for diverse business needs.",
    flexContent1Header: "Web Application Development",
    flexContent1Content: "Our team understands a wide range of digital technologies that help you build responsive, high-performance web applications tailored to your specific needs and prepare you for the best results.",
    flexContent1Image: service8FlexImage1,
    flexContent2Header: "Software Product Development",
    flexContent2Content: "Whether it is for up-and-coming businesses or legacy firms, Bot2Do helps transform product roadmaps into innovative, secure, scalable, and market-ready solutions.",
    flexContent2Image: service8FlexImage2,
    flexContent3Header: "Enterprise Software Solutions",
    flexContent3Content: "We help legacy businesses to modernize outdated, expensive, complex technology into innovative and scalable technology that helps you serve your customers better while adhering to market standards as well as improving operations.",
    flexContent3Image: service8FlexImage3,
    flexContent4Header: "Integration Services",
    flexContent4Content: "Bot2Do's team is well-versed in legacy and new-age digital systems which equips them to resolve various software integration challenges, such as integrating existing and new software, adding new partners to the system, and syncing different platforms, to name a few.",
    flexContent4Image: service8FlexImage4,
    flexContent5Header: "Software Consulting",
    flexContent5Content: "We offer cutting-edge software consulting services that are effective for a wide range of businesses as well as industries, allowing our clients to attain the best insights that can help them stay ahead of their competition",
    flexContent5Image: service8FlexImage5,
    flexContent6Header: "Mobile App Development",
    flexContent6Content: "Using the latest technologies, we're skilled at creating user-friendly and feature-packed mobile apps. Whether it's for a specific platform or works on multiple platforms, our mobile development services blend well with your business goals to establish a strong digital presence.",
    flexContent6Image: service8FlexImage6
  },
  "blockchain-services": {
    title: "Blockchain Services",
    content: "Understanding the growing need for secure and transparent transactions, our Blockchain Services are designed to address the challenges of modern business ecosystems. Our experts collaborate with your team to identify areas where blockchain can revolutionize your operations. From smart contracts to decentralized applications, our solutions leverage blockchain technology to enhance security and transparency.",
    image: mainImage9,
    list1: "360 Degree Blockchain Services",
    list2: "CoE Delivery Model",
    list3: "Bespoke Blockchain Solutions",
    list4: "Cross-Industry Expertise",
    list: "Client-Focused Approach",
    midContent1: "Blockchain revolutionizes data security, trust, and efficiency. It breaks boundaries, transforms businesses, and empowers the digital revolution by rewriting the rules of the game.",
    midContent2: "Blockchain for Enhanced Transparency, Security, and Traceability",
    midContent3: "We offer custom enterprise Blockchain development services, beginning with understanding business needs. Our approach covers strategy assessment, prototyping, solution design, implementation, third-party integration, add-ons, and ecosystem management, ensuring focused transformation for enterprises.",
    gridHeader: "Our Blockchain Services",
    gridContent: "With our multi-disciplined domain experts, technology know-how, and commercialization capabilities, we offer custom enterprise Blockchain development services for creating next-generation solutions for your unique business needs and holistic development.",
    flexContent1Header: "Ethereum Development",
    flexContent1Content: "Our Ethereum developers engage with the decentralized Ethereum Blockchain for dApp creation. Experienced in collaborating with B2B partners, we've delivered smart contracts and dApps for industries like Fintech, AdTech, and EdTech.",
    flexContent1Image: service9FlexImage1,
    flexContent2Header: "Smart Contracts",
    flexContent2Content: "Generating self-executable contracts to fit and function precisely in the clockwork of the project. Our customized Blockchain Smart Contract can help you widen the scope of trust by creating an automated contract management system which is faster, tamper-proof, and decentralized.",
    flexContent2Image: service9FlexImage2,
    flexContent3Header: "Proof of Concept (POC)",
    flexContent3Content: "We team up with your group to quickly create proofs of concept, assessing how Blockchain technology fits your idea. We pinpoint use-cases, choose the right platform, and design Blockchain architecture, measuring the business impact to kickstart the development journey.",
    flexContent3Image: service9FlexImage3,
    flexContent4Header: "Wallet Development",
    flexContent4Content: "Cryptocurrency wallet is a software functionality that allows users to earn, monitor and transfer virtual currencies. We have integrated various wallets, including multisig and multi-currency options, to facilitate secure cryptocurrency transactions.",
    flexContent4Image: service9FlexImage4,
    flexContent5Header: "Blockchain Consulting",
    flexContent5Content: "Choose our blockchain consulting services to evaluate business goals, organizational readiness, predict impact, and explore how Blockchain can catalyze significant enterprise transformation.",
    flexContent5Image: service9FlexImage5,
    flexContent6Header: "Cloud Blockchain Development",
    flexContent6Content: "As a seasoned Blockchain development firm, we specialize in dApp development using Amazon Managed Blockchain, Azure Blockchain Workbench, IBM Blockchain Platform, and Google Cloud Platform.",
    flexContent6Image: service9FlexImage6,
  },
  "digital-marketing": {
    title: "Digital Marketing",
    content: "Our Digital Marketing services are tailored to address your specific marketing goals and pain points. From enhancing online visibility through SEO to fostering audience engagement on social media, we craft a holistic strategy. Through content creation, analytics, and targeted campaigns, we position your brand for success in the digital realm.",
    image: mainImage10,
    list1: "Target Audience Understanding",
    list2: "Content Relevance",
    list3: "Multi-Channel Presence",
    list4: "Conversion Rate Optimization",
    list5: "Adaptability to new market",
    midContent1: "At Bot2Do, we offer a range of digital marketing services tailored for companies of all sizes, including small and mid-sized businesses. As a leading digital marketing service provider, we empower businesses with relevant insights and platforms needed to continue evolving and outperforming their expectations.",
    midContent2: "Delivering Real Results Powered by Our Technology and Marketing Prowess",
    midContent3: "Our speed-to-market is unparalleled, allowing you to capitalize on opportunities swiftly, without compromising on quality. We are a trusted partner for businesses seeking results in the ever-evolving digital landscape.",
    gridHeader: "Our Digital Marketing Services",
    gridContent: "Elevate your online presence with our expert digital marketing solutions. We understand the digital landscape, ensuring your brand thrives in the competitive online market. Let's boost your visibility and drive results together!",
    flexContent1Header: "Search Engine Optimization",
    flexContent1Content: "SEO is the practice of optimizing a website to improve its visibility on search engines like Google. The goal is to increase organic traffic to the website by optimizing content, improving website structure, and using relevant keywords.",
    flexContent1Image: service10FlexImage1,
    flexContent2Header: "Web Analytics",
    flexContent2Content: "Analyzing data is crucial for measuring the success of digital marketing efforts. By analyzing data, marketers can make informed decisions, optimize campaigns, and improve overall digital marketing performance.",
    flexContent2Image: service10FlexImage2,
    flexContent3Header: "Marketing Automation",
    flexContent3Content: "Marketing automation streamlines and automates repetitive tasks in digital marketing, enhancing efficiency and targeting. From email campaigns to social media scheduling, it optimizes workflows, nurtures leads, and analyzes data for more personalized and effective customer engagement.",
    flexContent3Image: service10FlexImage3,
    flexContent4Header: "Social Media Marketing",
    flexContent4Content: "Social media platforms like Facebook, Instagram, Twitter, LinkedIn, and others are used to promote products or services. SMM involves creating and sharing content on social media channels to engage with the target audience, build brand awareness, and drive traffic to the website.",
    flexContent4Image: service10FlexImage4,
    flexContent5Header: "Content Marketing",
    flexContent5Content: "Content marketing focuses on creating and distributing valuable, relevant, and consistent content to attract and retain a target audience. This content can include blog posts, articles, videos, infographics, and more. The goal is to provide value to the audience and establish the business as an authority in its industry.",
    flexContent5Image: service10FlexImage5,
    flexContent6Header: "Email Marketing",
    flexContent6Content: "Email marketing involves sending targeted messages to a specific group of people via email. This component is used for various purposes, including promoting products, sharing content, nurturing leads, and maintaining customer relationships. Effective email marketing strategies involve segmentation, personalization, and automation.",
    flexContent6Image: service10FlexImage6,
  },
  "qa-testing": {
    title: "QA & Testing",
    content: "Delivering a smooth user experience requires skilled debugging while ensuring speed and scalability. Through intelligent quality assurance practices, we can detect and address potential issues effectively. This highlights the importance of partnering with the right QA and Testing experts to translate your organization's efforts into functional solutions seamlessly. At Bot2Do Technologies, we specialize in ensuring error-free and reliable outcomes for your projects.",
    image: mainImage11,
    list1: "Enhanced Product Quality",
    list2: "Accelerated Delivery",
    list3: "Customer Satisfaction Improvement",
    list4: "Compliance and Regulatory Adherence",
    list5: "Cost Efficiency",
    midContent1: "At Bot2Do, we specialize in software testing services that streamline development cycles for our clients, ensuring functional assurance and a seamless user experience. Our certified experts conduct thorough testing to eradicate any errors or bugs that could impact your software solutions.",
    midContent2: "Assuring Excellence, Ensuring Reliability",
    midContent3: "We tailor custom testing strategies, guided by our software testing consulting professionals, to align with specific business needs. This includes comprehensive functional and non-functional testing, strategic AI-based testing, and a DevOps approach to deliver the most effective testing sequence and ensure error-free solutions.",
    gridHeader: "Our Quality Assurance services",
    gridContent: "Transforming development cycles, Bot2Do ensures flawless user experiences through expert debugging and intelligent quality assurance. Our tailored strategies guarantee error-free software solutions, supported by rigorous testing and certified expertise in functional, non-functional, AI-based, and DevOps testing approaches. Partner with us for seamless, scalable, and reliable software development.",
    flexContent1Header: "Test Consulting",
    flexContent1Content: "At Bot2Do Technologies, our Test Advisory Service empowers you to achieve software objectives and enhance maturity levels. We strategically align with your business needs and future roadmap. Additionally, we assist in establishing Test Centers of Excellence, defining vision, roadmap, and structure.",
    flexContent1Image: service11FlexImage1,
    flexContent2Header: "Functional Testing",
    flexContent2Content: "Functionally compliant software with DevOps-aligned Test Automation & Manual Testing. Our Regression Testing ensures software remains bug-free amid frequent changes, while Mobile Testing ensures mobile readiness with an efficient approach.",
    flexContent2Image: service11FlexImage2,
    flexContent3Header: "Non-Functional Testing",
    flexContent3Content: "Thoroughly test software stability under peak loads with Performance and Load Testing. Ensure security against cyber threats through rigorous Security Testing, focusing on integrity, authorization, and authentication. Enhance user experience with Usability Testing and maintain compliance with regulations.",
    flexContent3Image: service11FlexImage3,
    flexContent4Header: "Data Testing",
    flexContent4Content: "Safeguard critical data with strategic Performance and Security Testing, Business Functionality Testing, and thorough Audit/Data Quality Reports. Automate ETL Data Validation for accurate and reliable data processing. Enable continuous testing of ETL pipelines to ensure data integrity and reliability.",
    flexContent4Image: service11FlexImage4,
    flexContent5Header: "Next-Gen Testing",
    flexContent5Content: "Utilize Agile, Iterative, and DevOps methodologies for Digital and Web Testing to ensure seamless experiences. Specialize in Peer/Node Testing and Smart Contract Testing for Blockchain-based software. Provide IoT Testing services covering QA of IoT test automation tools, frameworks, and devices to ensure reliability and performance.",
    flexContent5Image: service11FlexImage5,
    flexContent6Header: "Specialized Testing",
    flexContent6Content: "Our API/Web Services Testing includes Unit, Load, Security, Runtime Error, and Web UI Testing. Enable global expansion with Linguistic, Cosmetic, and Functional Testing through Globalization & Localization Testing Services. Specialize in ERP, CRM, BPMS Testing, and provide Test Data Management Services to safeguard sensitive enterprise data.",
    flexContent6Image: service11FlexImage6,
  },
  "talent-service": {
    title: "Talent as a Service",
    content: "Finding the right talent for your IT projects is crucial for success. At Bot2Do Technologies, we offer comprehensive IT staffing solutions to match your specific needs. Our deep industry knowledge and extensive network of skilled professionals ensure that you have access to top-tier talent that seamlessly integrates into your projects.",
    image: mainImage12,
    list1: "Flexible Staff Augmentation",
    list2: "Expert IT Professionals",
    list3: "Customized Talent Solutions",
    list4: "Scalable Team Building",
    list5: "Cost-effective Resource Management",
    midContent1: "Bot2Do Technologies specializes in providing flexible IT staffing services, offering on-demand access to specialized professionals tailored to your project requirements. Whether you need short-term support or long-term expertise, our scalable solutions ensure you have the right talent at the right time.",
    midContent2: "Partnering for Success",
    midContent3: "We understand the importance of aligning talent with your organizational goals. Our IT staffing solutions are designed to enhance your project outcomes, optimize team performance, and drive innovation. Let us empower your projects with the right talent.",
    gridHeader: "Our IT Staffing Offerings",
    gridContent: "At Bot2Do Technologies, we offer comprehensive IT staffing services that cover a wide spectrum of expertise. From augmenting your existing team to building dedicated project teams, we provide agile and cost-effective talent solutions. Partner with us to leverage our industry-leading staffing practices.",
    flexContent1Header: "Staff Augmentation",
    flexContent1Content: "Expand your team with skilled IT professionals on a temporary or long-term basis. Our staff augmentation services provide immediate access to specialized talent, ensuring project continuity and efficiency.",
    flexContent1Image: service12FlexImage1,
    flexContent2Header: "Project-Based Staffing",
    flexContent2Content: "Build dedicated project teams with our project-based staffing solutions. We assemble experts tailored to your project requirements, optimizing collaboration and delivering exceptional results.",
    flexContent2Image: service12FlexImage2,
    flexContent3Header: "Technical Expertise",
    flexContent3Content: "Access a diverse pool of technical expertise through our IT staffing services. Whether you need software developers, QA testers, system administrators, or data analysts, we source and deliver the right talent for your projects.",
    flexContent3Image: service12FlexImage3,
    flexContent4Header: "Resource Management",
    flexContent4Content: "Optimize resource allocation and management with our IT staffing solutions. We help you streamline costs and maximize productivity by providing flexible workforce solutions that adapt to your evolving needs.",
    flexContent4Image: service12FlexImage4,
    flexContent5Header: "Strategic Partnership",
    flexContent5Content: "Forge a strategic partnership with Bot2Do Technologies to address your IT staffing challenges. We offer customized talent strategies that align with your business objectives, ensuring sustainable growth and success.",
    flexContent5Image: service12FlexImage5,
    flexContent6Header: "Skill Development and Training",
    flexContent6Content: "Invest in the growth of your team through our skill development and training programs. We offer customized training sessions to enhance the expertise of your existing workforce, ensuring they stay updated with the latest technologies and best practices.",
    flexContent6Image: service12FlexImage6

  },
};

const Templats = () => {
  const params = useParams();
  useEffect(() => {
    loadDataOnlyOnce();
  }, [])

  const loadDataOnlyOnce = () => {
    const scrollers = document.querySelectorAll('.customScroller')
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      scrollers.forEach(scroller => {
        scroller.setAttribute('data-animated', true);

        const scrollerInner = scroller.querySelector('.customScrollerInner');
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach(item => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute('aria-hidden', true);
          scrollerInner.appendChild(duplicatedItem)
        })
      });
    }
  }

  return (
    <div className='openpage-wrapper'>
      <MainHeader />
      <div className='openpage-section templates-page services'>
        <section className='banner-section'>
          <Container>
            <div
              className='slider-card'
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
                paddingTop: '0px',
              }}
            >
              <div className='slider-left'>
                <center>
                  <h1 className='customHeader'>{SERVICES[params.id].title}</h1>
                </center>
                <p style={{ fontSize: '25px', lineHeight: '1.4' }}>
                  {SERVICES[params.id].content}
                </p>
              </div>
            </div>
            <Row>
              <div className='bannerImage'>
                <img
                  src={SERVICES[params.id].image}
                  height='350'
                  alt={SERVICES[params.id].title}
                />
              </div>
            </Row>

            <div className='slider-card customSliderCard'>
              <div
                className='slider-left customSliderLeft'
                style={{ width: '60%' }}
              >
                <center>
                  <h2 style={{ fontSize: '34px' }}>
                    Why choose Bot2Do for <u>{SERVICES[params.id].title}</u>
                  </h2>
                </center>
                <p
                  style={{
                    lineHeight: '1.2',
                    fontSize: '23px',
                    marginTop: '20px',
                    marginBottom: '0px',
                  }}
                >
                  {SERVICES[params.id].midContent1}
                </p>
                <p
                  style={{
                    flineHeight: '1.2',
                    fontSize: '30px',
                    fontWeight: '400',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  {SERVICES[params.id].midContent2}
                </p>
                <p
                  style={{
                    lineHeight: '1.2',
                    fontSize: '23px',
                    marginTop: '20px',
                  }}
                >
                  {SERVICES[params.id].midContent3}
                </p>
              </div>
              <div className='customList'>
                <ul style={{ fontSize: '25px', lineHeight: '1.5' }}>
                  <li>{SERVICES[params.id].list1}</li>
                  <li>{SERVICES[params.id].list2}</li>
                  <li>{SERVICES[params.id].list3}</li>
                  <li>{SERVICES[params.id].list4}</li>
                  <li>{SERVICES[params.id].list5}</li>
                </ul>
              </div>
            </div>
            <div className='slider-card customHeaderSliderCard'>
              <h2>Trusted by 900+ happy clients</h2>
            </div>
          </Container>
          <div className='customScroller'>
            <div className='customScrollerInner'>
              {/* <div className='CustomScrollerInnerElements'>
                <img
                  src={Clientele}
                  height='100'
                  width='1510'
                  alt='clientele'
                />
              </div> */}
              {/* <div className="CustomScrollerInnerElements"><img src={Sephora} height="105" /></div> */}
              <div className="CustomScrollerInnerElements"><img src={BritishTelecom} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={FootLocker} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={GXO} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={JnJ} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={Kavida} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={Lululemon} height="105" /></div>
              <div className="CustomScrollerInnerElements"><img src={StitchFix} height="105" /></div>
            </div>
          </div>
          <Container>
            <div
              className='customSliderHeader'
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
                marginTop: '100px',
              }}
            >
              <div className='slider-left'>
                <center>
                  <h1 className='customHeader'>
                    {SERVICES[params.id].gridHeader}
                  </h1>
                </center>
                <p style={{ fontSize: '25px', lineHeight: '1.4' }}>
                  {SERVICES[params.id].gridContent}
                </p>
              </div>
            </div>

            <div className='mainFlex'>
              <div className='customFlexBox'>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent1Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent1Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent1Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent1Content}
                    </p>
                  </div>
                </div>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent2Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent2Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent2Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent2Content}
                    </p>
                  </div>
                </div>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent3Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent3Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent3Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent3Content}
                    </p>
                  </div>
                </div>
              </div>

              <div className='customFlexBox'>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent4Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent4Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent4Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent4Content}
                    </p>
                  </div>
                </div>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent5Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent5Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent5Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent5Content}
                    </p>
                  </div>
                </div>
                <div className='customFlexBoxInner'>
                  <div className='customFlexBoxInnerFront'>
                    <img
                      src={SERVICES[params.id].flexContent6Image}
                      height='50'
                      style={{ marginBottom: '10px' }}
                      alt={SERVICES[params.id].flexContent6Header}
                    />
                    <center>
                      <h2>{SERVICES[params.id].flexContent6Header}</h2>
                    </center>
                  </div>
                  <div className='customFlexBoxInnerBack'>
                    <p
                      className='customText'
                      style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                      {SERVICES[params.id].flexContent6Content}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='customSliderHeader'
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
                marginTop: '100px',
              }}
            >
              <div className='slider-left'>
                <center>
                  <h1 className='customHeader'>
                    Partnerships with Top Tier Cloud Companies
                  </h1>
                </center>
                <center>
                  <p style={{ fontSize: '25px', lineHeight: '1.4' }}>
                    We have proudly collaborated with Google Cloud Platform,
                    Amazon Web Services, and Microsoft Azure leveraging their
                    strengths to deliver advanced and customized solutions for
                    our clients.
                  </p>
                </center>
              </div>
            </div>
            <div className='customPartners'>
              <img src={GCP} alt='GCP' />
              <img src={AWS} alt='AWS' />
              <img src={Azure} alt='Azure' />
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  )
};

export default Templats;
