import React, { useState, useEffect, useRef } from "react";
import Container from "../grid/Container";
import { Button, Menu, Drawer } from "antd";
import { Link, NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";
import SvgIcon from "../svg-icon/svg-icon";
import "./index.less";
import { ServiceLi } from "./ServiceLi";
import { OurWorkLi } from "./OurWorkLi";
import Logo from "../../../assets/b/Bot2Do_Logo.svg";
import { motion } from "framer-motion";
import ExpandIcon from "./ServiceLi/ExpandIcon";

const items = [
  {
    label: <NavLink to="/about-us">About Us</NavLink>,
    key: "1",
  },
  {
    label: <NavLink to="/products">Products</NavLink>,
    key: "1",
  },
  // {
  //   label: <NavLink to="/#our-work">Our Work</NavLink>,
  //   key: "1",
  // },
  // {
  //   label: <NavLink to='#'>Services</NavLink>,
  //   key: '1',
  //   children: [
  //     {
  //       label: (
  //         <NavLink to='/service/cloud-transformation'>
  //           Cloud Transformation
  //         </NavLink>
  //       ),
  //       key: 'cloud-transformation',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/devOps-service'>DevOps as a Service</NavLink>
  //       ),
  //       key: 'devOps-service',
  //     },
  //     {
  //       label: <NavLink to='/service/data-analytics'>Data & Analytics</NavLink>,
  //       key: 'data-analytics',
  //     },
  //     {
  //       label: <NavLink to='/service/ai-ml'>AI & ML</NavLink>,
  //       key: 'ai-ml',
  //     },
  //     {
  //       label: <NavLink to='/service/desing-ux'>Design & UX</NavLink>,
  //       key: 'desing-ux',
  //     },
  //     {
  //       label: <NavLink to='/service/data-processing'>Data Processing</NavLink>,
  //       key: 'data-processing',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/product-development'>
  //           Product Development
  //         </NavLink>
  //       ),
  //       key: 'product-development',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/software-development'>
  //           Software Development
  //         </NavLink>
  //       ),
  //       key: 'digital-marketing',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/blockchain-services'>
  //           Blockchain Services
  //         </NavLink>
  //       ),
  //       key: 'blockchain-services',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/digital-marketing'>Digital Marketing</NavLink>
  //       ),
  //       key: 'digital-marketing',
  //     },
  //     {
  //       label: <NavLink to='/service/qa-testing'>QA & Testing</NavLink>,
  //       key: 'qa-testing',
  //     },
  //     {
  //       label: (
  //         <NavLink to='/service/talent-service'>Talent as a Service</NavLink>
  //       ),
  //       key: 'talent-service',
  //     },
  //   ],
  // },
];

const MainHeader = () => {
  const [open, setOpen] = useState(false);
  const headerEl = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);

  const handleDropdownEnter = () => {
    setShowProductsDropdown(true);
  };
  const closeDropdowns = () => {
    setShowProductsDropdown(false);
  };

  const handleDropdownEnterPolicy = () => {
    setShowPolicyDropdown(true);
  };

  const closeDropdownsPolicy = () => {
    setShowPolicyDropdown(false);
  };

  const nav = {
    id: 1,
    title: "Products",
    productsList: [
      { id: 1, href: "https://scalesecure.io/", title: "ScalesSecure" },
    ],
  };

  const policy = {
    id: 1,
    title: "Policy",
    productsList: [
      { id: 1, href: "/terms", title: "Terms & Conditions" },
      { id: 1, href: "/privacy-policy", title: "Privacy Policy" },
    ],
  };

  const isActive = true;

  useEffect(() => {
    window.addEventListener("scroll", onScroll, false);

    return () => {
      window.removeEventListener("scroll", onScroll, false);
    };
  }, []);

  const onScroll = () => setOffsetTop(headerEl.current.offsetTop);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div
      ref={headerEl}
      className={offsetTop > 80 ? "opsl-header active" : "opsl-header"}
    >
      <Container>
        <div className="opslheader-inner">
          <div className="logo">
            <Link
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <img src={Logo} alt={Logo} />
            </Link>
          </div>
          <MediaQuery minWidth={768}>
            {/* <Menu mode='horizontal' items={items} /> */}
            <div className="head-wrap">
              <NavLink to="/about-us">About Us</NavLink>
              {/* <NavLink to="/#our-work">Our Work</NavLink> */}
            </div>
          </MediaQuery>
          <div className="header-services">
            <OurWorkLi />
          </div>
          <div className="header-services">
            <ServiceLi />
          </div>

          {/* Add Products Dropdown */}
          <div className="header-services">
            <div
              className="dropdown-wrapper"
              onMouseLeave={() => setShowProductsDropdown(false)}
            >
              <li key={nav.id} className="dropdown-item">
                <button
                  className={`dropdown-button ${isActive ? "active" : ""}`}
                  onMouseEnter={handleDropdownEnter}
                >
                  {nav.title}

                  <motion.svg
                    whileHover={{ scale: 1.2 }}
                    initial={{ scale: 1 }}
                    animate={{
                      scale: 1,
                      rotate: showProductsDropdown ? 180 : 0,
                    }}
                    className={`dropdown-icon ${isActive ? "active" : ""}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </motion.svg>
                </button>
                {showProductsDropdown && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95, y: 10 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    className="dropdown-container"
                  >
                    <div className="dropdown-grid">
                      {nav.productsList.map((product) => (
                        <Link
                          key={product.id}
                          to={product.href}
                          className="dropdown-link"
                          onClick={closeDropdowns}
                        >
                          {product.title}
                        </Link>
                      ))}
                    </div>
                  </motion.div>
                )}
              </li>
            </div>
          </div>

          {/* Policy navlink */}
          <div className="header-services">
            <div
              className="dropdown-wrapper"
              onMouseLeave={() => setShowPolicyDropdown(false)}
            >
              <li key={policy.id} className="dropdown-item">
                <button
                  className={`dropdown-button ${isActive ? "active" : ""}`}
                  onMouseEnter={handleDropdownEnterPolicy}
                >
                  {policy.title}

                  <motion.svg
                    whileHover={{ scale: 1.2 }}
                    initial={{ scale: 1 }}
                    animate={{
                      scale: 1,
                      rotate: showPolicyDropdown ? 180 : 0,
                    }}
                    className={`dropdown-icon ${isActive ? "active" : ""}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </motion.svg>
                </button>
                {showPolicyDropdown && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95, y: 10 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    className="dropdown-container"
                  >
                    <div className="dropdown-grid">
                      {policy.productsList.map((product) => (
                        <Link
                          key={product.id}
                          to={product.href}
                          className="dropdown-link"
                          onClick={closeDropdownsPolicy}
                        >
                          {product.title}
                        </Link>
                      ))}
                    </div>
                  </motion.div>
                )}
              </li>
            </div>
          </div>

          <div className="header-right">
            <MediaQuery minWidth={769}>
              <a
                href="https://cal.com/bot2do/30min"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" className="getstarted-btn">
                  Contact Us
                </Button>
              </a>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <Button
                className="mobilemenu-btn"
                type="primary"
                onClick={showDrawer}
              >
                <SvgIcon name="menu" viewbox="0 0 27 21.569" />
              </Button>
            </MediaQuery>
            <Drawer
              className="menu-drawer"
              title={false}
              width={220}
              placement="left"
              onClose={onClose}
              open={open}
            >
              <div className="drawer-main">
                <div className="drawer-top">
                  <Menu mode="vertical" items={items} />
                  <div className="header-services-mobile">
                    <OurWorkLi />
                  </div>
                  <div className="header-services-mobile">
                    <ServiceLi />
                  </div>
                </div>
                <div className="drawer-bottom">
                  <a
                    href="https://cal.com/bot2do/30min"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button type="text" className="getstarted-btn">
                      Contact Us
                    </Button>
                  </a>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainHeader;
