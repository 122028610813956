import React from "react";
import "./PrivacyPolicy.less";
import MainHeader from "../common/MainHeader";
import { Container, Footer } from "../common";

const PrivacyPolicy = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section landing-page">
        <section className="banner-section">
          <Container>
            <h1>Acceptance of Terms of Use Agreement</h1>
            <p>
              By using Bot2Do Technologies Pvt. Ltd., you agree to the following
              terms and conditions:
            </p>

            <h1>1.Information We Collect</h1>
            <p>
              - Personal Information : When you sign up for an account or use
              certain features of Bot2Do Technologies Pvt. Ltd., we may collect
              personal information, such as your name, email address, and
              contact information.
            </p>
            <p>
              - Usage Data : We may collect information about how you use Bot2Do
              Technologies Pvt. Ltd., including your interactions with the tool,
              preferences, and settings.
            </p>
            <p>
              - Device Information : We may collect information about the device
              you use to access Bot2Do Technologies Pvt. Ltd., such as your
              device type, operating system, and browser.
            </p>
            <p>
              - Log Data : Like many websites and online services, we
              automatically collect certain information when you access Bot2Do
              Technologies Pvt. Ltd., including your IP address, browser type,
              and pages visited.
            </p>

            <h1>2. How We Use Your Information</h1>
            <p>
              - We use the information collected to provide and improve Bot2Do
              Technologies Pvt. Ltd., customize your experience, and respond to
              your inquiries or support requests.
            </p>
            <p>
              - Your personal information may be used to send you notifications,
              updates, or marketing materials related to Bot2Do Technologies
              Pvt. Ltd.. You can opt-out of these communications at any time.
            </p>
            <p>
              - We may use aggregated and anonymized data for research,
              analytics, and statistical purposes.
            </p>

            <h1>3. Data Sharing and Disclosure</h1>
            <p>
              - We may share your personal information with trusted third-party
              service providers who assist us in operating and maintaining
              Bot2Do Technologies Pvt. Ltd.. These service providers are
              obligated to keep your information confidential and are prohibited
              from using it for any other purpose.
            </p>
            <p>
              - Your information may be disclosed if required by law,
              regulation, or legal process, or if we believe that such action is
              necessary to protect our rights, prevent fraud, or ensure the
              safety of our users.
            </p>
            <p>
              - In the event of a merger, acquisition, or sale of all or a
              portion of our assets, your personal information may be
              transferred as part of the transaction, subject to the same
              privacy protections.
            </p>

            <h1>4. Data Security</h1>
            <p>
              - We implement appropriate security measures to protect your
              personal information from unauthorized access, alteration,
              disclosure, or destruction.
            </p>
            <p>
              - However, no method of transmission over the internet or
              electronic storage is entirely secure, and we cannot guarantee the
              absolute security of your data.
            </p>

            <h1>5. Third-Party Links</h1>
            <p>
              - Bot2Do Technologies Pvt. Ltd. may contain links to third-party
              websites or services. This Privacy Policy applies only to Bot2Do
              Technologies Pvt. Ltd., and we are not responsible for the privacy
              practices of third-party websites. Please review the privacy
              policies of those websites before providing any personal
              information.
            </p>

            <h1>6. Data Retention</h1>
            <p>
              - We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, or as
              required by law.
            </p>

            <h1>7. Your Privacy Choices</h1>
            <p>
              - You can review and update your personal information by accessing
              your account settings on Bot2Do Technologies Pvt. Ltd..
            </p>
            <p>
              - You can opt-out of receiving marketing communications by
              following the unsubscribe instructions included in the email or
              contacting us at the provided contact information.
            </p>

            <h1>8. Changes to this Policy</h1>
            <p>
              - We may update this Privacy Policy from time to time. The revised
              version will be posted on this page with the effective date. We
              recommend reviewing this page periodically to stay informed about
              our privacy practices.
            </p>

            <h1>9. Contact Information -</h1>
            <p>
              - If you have any questions, concerns, or requests regarding this
              Privacy Policy or the use of Bot2Do Technologies Pvt. Ltd., please
              contact us at contact@bot2do.com or reach out to us at the
              following address: Registered Company Name: Bot2Do Technologies
              Pvt. Ltd. Address: 10, VRS Nagar, Sri Krishna Nagar, Alapakkam,
              Mangadu, Chennai, Tamil Nadu 600122, India
            </p>

            {/* --************************************************************** */}
            <p>
              Thank you for trusting Bot2Do Technologies Pvt. Ltd.. Your privacy
              is important to us, and we are committed to safeguarding your
              personal information.
            </p>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
