export const service = {
  title: "Services",
  href: "#",
  isSubMenuList: true,
  list: [
    {
      title: "Cloud & Data Services",
      info: "DevOps, cloud transformation, data & analytics, data processing",
      href: "#",
      menu: [
        {
          title: "DevOps",
          info: "DevOps practices and methodologies",
          href: "/service/devOps-service",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Cloud Transformation",
          info: "Transformation of IT infrastructure to cloud-based solutions",
          href: "/service/cloud-transformation",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Data & Analytics",
          info: "Data analysis and interpretation for insights",
          href: "/service/data-analytics",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Data Processing",
          info: "Processing and manipulation of data",
          href: "/service/data-processing",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
    {
      title: "Development Services",
      info: "Product development, software development",
      href: "#",
      menu: [
        {
          title: "Product Development",
          info: "Development of new products",
          href: "/service/product-development",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Software Development",
          info: "Development of software applications",
          href: "/service/software-development",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
    {
      title: "AI Services",
      info: "AI/ML, Blockchain",
      href: "#",
      menu: [
        {
          title: "AI/ML",
          info: "Artificial Intelligence and Machine Learning solutions",
          href: "/service/ai-ml",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Blockchain",
          info: "Blockchain technology solutions",
          href: "/service/blockchain-services",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
    {
      title: "Marketing Services",
      info: "Digital marketing, design & UX",
      href: "#",
      menu: [
        {
          title: "Digital Marketing",
          info: "Online marketing strategies and campaigns",
          href: "/service/digital-marketing",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Design & UX",
          info: "User experience design and interface design",
          href: "/service/desing-ux",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
    {
      title: "Other Services",
      info: "QA & Testing, talent as service",
      href: "#",
      menu: [
        {
          title: "QA & Testing",
          info: "Quality assurance and testing processes",
          href: "/service/qa-testing",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Talent as Service",
          info: "Access to skilled professionals as a service",
          href: "/service/talent-service",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
  ],
};

export const ourWorkData = {
  title: "OurWork",
  href: "#",
  isSubMenuList: true,
  list: [
    {
      title: "Our Work",
      info: "DevOps, cloud transformation, data & analytics, data processing",
      href: "#",
      menu: [
        // {
        //   title: "Johnson & Johnson",
        //   info: "Data Warehouse",
        //   // href: '/service/devOps-service',
        //   href: "/our-work/1",
        //   imgSrc: "/static/services-mini-icon.svg",
        // },
        {
          title: "Kavida",
          info: "AI Driven Procurement Management System",
          // href: '/service/cloud-transformation',
          href: "/our-work/2",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Footlocker",
          info: "Realtime Order Management System",
          // href: '/service/data-analytics',
          href: "/our-work/3",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "British Telecom",
          info: "Telecom Provisioning System",
          // href: '/service/data-processing',
          href: "/our-work/4",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Lululemon",
          info: "Supply Chain Control Tower",
          // href: '/service/data-processing',
          href: "/our-work/5",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Sephora",
          info: "Order Commits & Sourcing",
          // href: '/service/data-processing',
          href: "/our-work/6",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "GXO",
          info: "Realtime Inventory Management System",
          // href: '/service/data-processing',
          href: "/our-work/7",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Stitchfix",
          info: "Supply Chain Control Tower Analytics Platform",
          // href: '/service/data-processing',
          href: "/our-work/8",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Bot2Do",
          info: "AI Driven Automated Cloud Deployments",
          // href: '/service/data-processing',
          href: "/our-work/9",
          imgSrc: "/static/services-mini-icon.svg",
        },
        // {
        //   title: "Johnson & Johnson",
        //   info: "AWS Decoupling Architecture",
        //   // href: '/service/data-processing',
        //   href: "/our-work/10",
        //   imgSrc: "/static/services-mini-icon.svg",
        // },
      ],
    },
    {
      title: "Blogs",
      info: "Product development, software development",
      href: "#",
      menu: [
        {
          title: "Health Care",
          info: "Streamlining Healthcare Data: A Success Story",
          href: "/our-work/blog/1",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Supply Chain",
          info: "Streamlining Supply Chains: How Bot2Do Revolutionized Real-time Inventory Management",
          href: "/our-work/blog/2",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Retail",
          info: "Improving Invoice Tracking: How Bot2Do Revolutionized Invoice Tracking",
          href: "/our-work/blog/2",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Health Care",
          info: "Streamlining Healthcare: A Tech-Powered Revolution",
          href: "/our-work/blog/2",
          imgSrc: "/static/services-mini-icon.svg",
        },
        {
          title: "Telecommunications",
          info: "How Tech Transformed an Auditing Firm",
          href: "/our-work/blog/2",
          imgSrc: "/static/services-mini-icon.svg",
        },
      ],
    },
    // {
    //   title: 'Case Study',
    //   info: 'AI/ML, Blockchain',
    //   href: '#',
    //   menu: [
    //     {
    //       title: 'Case Study 1',
    //       info: 'Artificial Intelligence and Machine Learning solutions',
    //       href: '/our-work/case-studies/ai-ml',
    //       imgSrc: '/static/services-mini-icon.svg',
    //     },
    //     {
    //       title: 'Case Study 2',
    //       info: 'Blockchain technology solutions',
    //       href: '/our-work/case-studies/blockchain-services',
    //       imgSrc: '/static/services-mini-icon.svg',
    //     },
    //   ],
    // },
  ],
};
