import React, { useState } from "react";
import { useCategory } from "../../context/CategoryContext";
import { blogs } from "./blogData";
import "./GridBlogsCards.less";

const GridBlogsCards = () => {
  const { selectedCategory } = useCategory();
  const [expandedId, setExpandedId] = useState(null);

  // Function to handle expanding description
  const handleExpand = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };

  // Filter blogs based on the selected category
  const filteredBlogs =
    selectedCategory === "All"
      ? blogs
      : blogs.filter((blog) => blog.category === selectedCategory);

  return (
    <div className={"grid-blogs-container"}>
      <h2 className={"grid-blogs-title"}>Latest Blog Posts</h2>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        {filteredBlogs.map((blog) => (
          <div key={blog.id} className={"blog-card"}>
            <a href={`/our-work/blog/${blog.id}`}>
              <img src={blog.image} alt="Blog Image" />
            </a>
            <div className={"blog-card-content"}>
              <a href={`/our-work/blog/${blog.id}`}>
                <div>
                  <p className={"blog-category"}>{blog.category}</p>
                  <h3 className={"blog-heading"}>{blog.heading}</h3>
                  <p className={"blog-description"}>
                    {expandedId === blog.id
                      ? blog.description
                      : `${blog.description.substring(0, 150)}...`}
                  </p>
                  <p className={"blog-time"}>{blog.time}</p>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridBlogsCards;
