import React from 'react'
import Container from '../grid/Container'
import { Button } from 'antd'
import Col from '../grid/Col'
import Row from '../grid/Row'
import SvgIcon from '../svg-icon/svg-icon'
import { Link } from 'react-router-dom'
import './index.less'

import logoLight from '../../../assets/b/Bot2Do_Logo.svg'

const Footer = () => {
  return (
    <div className='main-footer'>
      <Container>
        <div
          className='footer-inner'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          <div className='footer-upper'>
            <Row className='align-items-center'>
              <Col md='6'>
                <h2>
                  Don't wait for change, <br /> Make it happen!
                </h2>
                <a
                  href='https://cal.com/bot2do/30min'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button className='signup-btn wave-btn'>Contact Us</Button>
                </a>
              </Col>
              <Col md='6'>
                <ul>
                  <li>
                    <h3>SUPPORT</h3>
                    <a href='mailto:contact@bot2do.com'>Email Us</a>
                    <a
                      href='https://cal.com/bot2do/30min'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <h3>Company</h3>
                    <Link to='/about-us'>About Us</Link>
                  </li>
                  <li>
                    <h3>Policy</h3>
                    <Link to='/terms'>Terms & Conditions</Link>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className='footer-bottom'>
            <div className='logo-left'>
              <img src={logoLight} alt='logo' />
            </div>
            <div className='social-right'>
              {/* <Button href='/' type='link' target='_blank'>
                <SvgIcon
                  name='facebook-icon'
                  viewbox='0 0 6.202 13.27'
                  // style={{ transform: 'scaleY(3)' }}
                />
              </Button> */}
              <Button href='https://www.linkedin.com/company/bot2do' type='link' target='_blank'>
                <SvgIcon name='linkedin-icon' viewbox='0 0 12.367 12.345' />
              </Button>
              {/* <Button href='/' type='link' target='_blank'>
                <SvgIcon name='medium-icon' viewbox='0 0 24 20' />
              </Button> */}
              <Button href='https://www.instagram.com/bot2do' type='link' target='_blank'>
                <SvgIcon name='instagram-icon' viewbox='0 0 13.11 13.11' />
              </Button>
            </div>
          </div>
        </div>
        <div className='copyroight-bottom'>
          <div className='left-col'>
            <span className='copy-riht-sign'>©️</span>Bot2Do Technologies
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
