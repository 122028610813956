import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import SvgSprite from "./utils/SvgSpriteLoader";
import svgFile from "./assets/b/svg-sprite.svg";
import "antd/dist/reset.css";
import "./App.less";

import Templats from "./containers/Templats";
import LandingPage from "./containers/LandingPage";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutUS from "./containers/about";
import OurWorkTemplate from "./containers/OurWorkTemplate";
import BlogPage from "./containers/BlogPage/index";
import CaseStudy from "./containers/CaseStudy";
import { CategoryProvider } from "./context/CategoryContext";
import SubBlog from "./containers/BlogPage/SubBlog/SubBlog";
import Terms from "./components/Policy/Terms";
import PrivacyPolicy from "./components/Policy/PrivacyPolicy";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <React.Fragment>
      <CategoryProvider>
        <SvgSprite url={svgFile} />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about-us" element={<AboutUS />} />
            <Route path="/service/:id" element={<Templats />} />
            <Route path="/our-work/:id" element={<OurWorkTemplate />} />
            {/* <Route path="/our-work/blog/:id" element={<BlogPage />} /> */}
            <Route path="/our-work/blog/" element={<BlogPage />} />
            <Route path="/our-work/blog/:id" element={<SubBlog />} />
            <Route path="/our-work/case-studies/:id" element={<CaseStudy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </CategoryProvider>
    </React.Fragment>
  );
}

export default App;
