import React, { memo, useState } from 'react'
import { Col, Container, Row } from '../../../components/common'
import SolutionImg6 from '../../../assets/b/solution-img6.jpg'
import craftingMade from '../../../assets/b/crafting-made.mp4'

const WhatsSection = () => {
  const [videoTime, setVideoTime] = useState(0)
  return (
    <section className='whats-section'>
      <Container>
        {/* <Row>
              <Col data-aos="fade-in" data-aos-duration="1000">
                <div className="k30slide">
                  <h1>
                    <b>10</b> Countri
                    <span className="overlay-tag" data-value="Served">
                      es
                    </span>
                  </h1>
                </div>
              </Col>
            </Row> */}
        <Row>
          <Col>
            <div className='common-heading'>
              <p data-aos='fade-up' data-aos-duration='800'>
                Who we are?
              </p>
              <h2 data-aos='fade-up' data-aos-duration='1000'>
                We build digital products for tomorrow <br /> to help businesses
                succeed today
              </h2>
            </div>
            <div className='whats-row'>
              <div className='whatscol-left'>
                <ul>
                  <li
                    className={`counts-container ${
                      videoTime >= 0 && videoTime < 2 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='800'
                  >
                    <div className='counts '>01</div> Strategy
                  </li>
                  <li
                    className={`counts-container ${
                      videoTime >= 2 && videoTime < 4.5 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='800'
                  >
                    <div className='counts'>02</div> Design
                  </li>
                  <li
                    className={`counts-container ${
                      videoTime >= 4.5 && videoTime < 7.5 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='900'
                  >
                    <div className='counts'>03</div> Develop
                  </li>
                  <li
                    className={`counts-container ${
                      videoTime >= 7.5 && videoTime < 10 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='1000'
                  >
                    <div className='counts'>04</div> Iterate
                  </li>
                  <li
                    className={`counts-container ${
                      videoTime >= 10 && videoTime < 14 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='1100'
                  >
                    <div className='counts'>05</div> Innovate
                  </li>
                  <li
                    className={`counts-container ${
                      videoTime >= 14 && videoTime < 18 ? 'animate' : null
                    }`}
                    // data-aos='fade-right'
                    // data-aos-duration='1200'
                  >
                    <div className='counts'>06</div> Maintain
                  </li>
                </ul>
              </div>
              <div className='whatscol-right'>
                <video
                  poster={SolutionImg6}
                  playsInline
                  autoPlay
                  muted
                  loop
                  onTimeUpdate={(e) => {
                    setVideoTime(e.target.currentTime)
                  }}
                >
                  <source src={craftingMade} />
                </video>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default memo(WhatsSection)
