import React, { useEffect, useState } from 'react'
import BallCanvas from './Ball'
import kafka from '../../../../assets/b/tech/kafka.png'
import python from '../../../../assets/b/tech/python.png'
import sql from '../../../../assets/b/tech/sql.png'

import './index.less'

const ProblemStatementCard = ({ index, title, desc, isSticky, isImg }) => {
  const [sticky, setSticky] = useState(false)
  const [position, setPosition] = useState(null)
  // useEffect(() => {
  //   if (isSticky) {
  //     const titleWrapper = document.querySelector('.title-wrapper')
  //     const descriptionWrapper = document.querySelector('.description-wrapper')
  //     const handleScroll = () => {
  //       const descriptionBottom =
  //         descriptionWrapper.getBoundingClientRect().bottom
  //       const titleHeight = titleWrapper.offsetHeight
  //       console.log(descriptionBottom, titleHeight)
  //       if (descriptionBottom < -1740) {
  //         setSticky(false)
  //         setPosition(-1)
  //       } else if (descriptionBottom < 30) {
  //         setSticky(true)
  //         setPosition(100)
  //       } else {
  //         setSticky(false)
  //         setPosition(null)
  //       }
  //     }
  //     window.addEventListener('scroll', handleScroll)
  //     return () => {
  //       window.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  // }, [isSticky])

  return (
    <div className='problem-statement'>
      <div
        className={sticky ? 'title-wrapper sticky' : 'title-wrapper'}
        style={position === -1 ? { position: 'relative' } : null}
      >
        <div
          className='title'
          style={
            sticky
              ? { position: 'fixed', top: `${position}px` }
              : position
              ? { position: 'absolute', bottom: '0px' }
              : null
          }
        >
          <h2 className='title-text'>
            <span>0{index + 1}</span>
            {title}:
          </h2>
        </div>
      </div>
      <div className='description-wrapper'>
        {isImg ? (
          //TODO: add images and tech stack name.

          <div className='image-ball-wrapper'>
            {desc.data?.map((tech) => {
              return (
                <div className='image-ball'>
                  <BallCanvas
                    icon={
                      tech.img
                        ? `/static/tech/${tech.img}`
                        : `/static/tech/python.png`
                    }
                  />
                  {tech.tag}
                </div>
              )
            })}

            {/* <div className='image-ball'>
              <BallCanvas icon={sql} />
              SQL Database
            </div>
            <div className='image-ball'>
              <BallCanvas icon={kafka} />
              Apache Kafka
            </div>
            <div className='image-ball'>
              <BallCanvas icon={python} />
              Python
            </div>
            <div className='image-ball'>
              <BallCanvas icon={sql} />
              SQL Database
            </div>
            <div className='image-ball'>
              <BallCanvas icon={kafka} />
              Apache Kafka
            </div> */}
          </div>
        ) : (
          <>
            <div className='description-text'>{desc?.text}</div>
            {desc?.solutionPoints?.map((solution, index) => {
              return (
                <>
                  {desc.solutionPoints.length > 1 && (
                    <div className='solution-number'>Solution {index + 1}</div>
                  )}
                  {solution?.map((data, index) => (
                    <div className='description-points'>
                      <div className='description-points-heading'>
                        <p>
                          {index + 1}. {data.heading}:
                        </p>
                      </div>
                      <div className='description-points-list-wrapper'>
                        <ul className='description-points-list'>
                          {data.points?.map((point) => (
                            <li className='description-points-list-item'>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default ProblemStatementCard
