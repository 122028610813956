import React from "react";
import "./PrivacyPolicy.less";
import MainHeader from "../common/MainHeader";
import { Container, Footer } from "../common";

const Terms = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section landing-page">
        <section className="banner-section">
          <Container>
            <h1>Acceptance of Terms of Use Agreement</h1>
            <p>
              By using Bot2Do Technologies Pvt. Ltd., you agree to the following
              terms and conditions:
            </p>
            <ol>
              <li>
                <p>
                  Acceptance of Terms: By accessing or using Bot2Do Technologies
                  Pvt. Ltd., you acknowledge that you have read, understood, and
                  agreed to these terms and conditions. If you do not agree with
                  any part of these terms, you should refrain from using the
                  service.
                </p>
              </li>
              <li>
                <p>
                  Use of the Service: Bot2Do Technologies Pvt. Ltd. is provided
                  for personal and non-commercial use only. You may not use the
                  service for any illegal or unauthorized purposes. You are
                  solely responsible for your use of the tool and any content
                  you create or share using it.
                </p>
              </li>
              <li>
                <p>
                  Intellectual Property: All intellectual property rights,
                  including but not limited to copyrights, trademarks, and
                  patents, associated with Bot2Do Technologies Pvt. Ltd. and its
                  content, are owned by Bot2Do Technologies Pvt. Ltd.. You may
                  not reproduce, modify, or distribute any part of the service
                  without prior written permission from Bot2Do Technologies Pvt.
                  Ltd..
                </p>
              </li>
              <li>
                <p>
                  Privacy: We respect your privacy and handle your personal data
                  in accordance with our Privacy Policy. By using Bot2Do
                  Technologies Pvt. Ltd., you consent to the collection, use,
                  and sharing of your data as outlined in the Privacy Policy.
                </p>
              </li>
              <li>
                <p>
                  Disclaimer of Warranties: Bot2Do Technologies Pvt. Ltd. is
                  provided on an “as is” basis without any warranties or
                  guarantees of any kind, either expressed or implied. We do not
                  warrant that the service will be error-free, uninterrupted, or
                  secure.
                </p>
              </li>
              <li>
                <p>
                  Limitation of Liability: Bot2Do Technologies Pvt. Ltd. and its
                  affiliates shall not be liable for any direct, indirect,
                  incidental, consequential, or exemplary damages arising from
                  the use or inability to use the service. This includes damages
                  for lost profits, data, or any other intangible losses, even
                  if we have been advised of the possibility of such damages.
                </p>
              </li>
              <li>
                <p>
                  Modifications and Termination: Bot2Do Technologies Pvt. Ltd.
                  reserves the right to modify, suspend, or terminate the
                  service or any part of it at any time without prior notice. We
                  may also impose limits on certain features or restrict access
                  to the service without liability.
                </p>
              </li>
              <li>
                <p>
                  User Content: Any content created, uploaded, or shared using
                  Bot2Do Technologies Pvt. Ltd. is the sole responsibility of
                  the user. Bot2Do Technologies Pvt. Ltd. does not claim
                  ownership of user-generated content, but by using the service,
                  you grant us a worldwide, non-exclusive, royalty-free license
                  to use, reproduce, modify, adapt, publish, translate, and
                  distribute such content for the purpose of providing and
                  improving the service.
                </p>
              </li>
              <li>
                <p>
                  User Responsibilities: You are responsible for maintaining the
                  confidentiality of your account credentials and ensuring the
                  security of your account. You agree not to share your account
                  information with third parties or permit unauthorized access
                  to the service using your account.
                </p>
              </li>
              <li>
                <p>
                  Prohibited Activities: You may not use Bot2Do Technologies
                  Pvt. Ltd. for any unlawful, harmful, or malicious purposes.
                  Prohibited activities include but are not limited to hacking,
                  spreading malware, infringing copyrights, or engaging in any
                  activities that violate applicable laws or regulations.
                </p>
              </li>
              <li>
                <p>
                  Third-Party Services: Bot2Do Technologies Pvt. Ltd. may
                  integrate with or provide links to third-party services or
                  websites. These third-party services are not under the control
                  of Bot2Do Technologies Pvt. Ltd., and we are not responsible
                  for their content or actions. Your use of any third-party
                  services is subject to their respective terms and policies.
                </p>
              </li>
              <li>
                <p>
                  Changes to the Service: Bot2Do Technologies Pvt. Ltd. reserves
                  the right to update, modify, or discontinue any part of Bot2Do
                  Technologies Pvt. Ltd., including features, functionality, or
                  availability, at any time without prior notice.
                </p>
              </li>
              <li>
                <p>
                  Service Accessibility: While we strive to provide a reliable
                  and uninterrupted service, Bot2Do Technologies Pvt. Ltd. may
                  be subject to downtime for maintenance, updates, or unforeseen
                  issues. We shall not be held liable for any losses or damages
                  incurred due to service unavailability.
                </p>
              </li>
              <li>
                <p>
                  Termination of Service: Bot2Do Technologies Pvt. Ltd. reserves
                  the right to terminate your access to Bot2Do Technologies Pvt.
                  Ltd. at its discretion, with or without cause, and without
                  liability. Upon termination, your right to use the service
                  will cease immediately.
                </p>
              </li>
              <li>
                <p>
                  Changes to Terms: Bot2Do Technologies Pvt. Ltd. may revise
                  these terms and conditions at any time by updating this page.
                  Your continued use of Bot2Do Technologies Pvt. Ltd. after any
                  such changes constitutes your acceptance of the updated terms.
                </p>
              </li>
              <li>
                <p>
                  Entire Agreement: These terms and conditions constitute the
                  entire agreement between you and Bot2Do Technologies Pvt. Ltd.
                  regarding the use of Bot2Do Technologies Pvt. Ltd. and
                  supersede any prior agreements or communications.
                </p>
              </li>
              <li>
                <p>
                  Governing Law: These terms and conditions shall be governed by
                  and construed in accordance with the laws of India, without
                  regard to its conflict of laws principles.
                </p>
              </li>
              <li>
                <p>
                  Contact Information: If you have any questions, comments, or
                  concerns about these terms and conditions or the use of Bot2Do
                  Technologies Pvt. Ltd., please contact us at
                  contact@bot2do.com or reach out to us at the following
                  address: Registered Company Name: Bot2Do Technologies Pvt.
                  Ltd. Address: 10, VRS Nagar, Sri Krishna Nagar, Alapakkam,
                  Mangadu, Chennai, Tamil Nadu 600122, India
                </p>
              </li>
            </ol>

            <p>
              By using Bot2Do Technologies Pvt. Ltd., you agree to comply with
              these terms and conditions. Thank you for choosing Bot2Do
              Technologies Pvt. Ltd.!
            </p>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Terms;
